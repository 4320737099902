import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { cloneDeep, cleanString } from 'components/modules/_misc';

export default class Textbox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hide_invalid: true
		};
	}

	render() {
		let {
				disabled,
				value,
				type,
				onChange,
				name,
				label,
				note_long,
				note_short,
				classVals,
				required,
				minLength,
				min,
				max,
				isInvalid,
				cols,
				hide_optional,
				tooltip,
				onBlur
			} = this.props,
			{ hide_invalid } = this.state,
			highlight_invalid =
				!hide_invalid &&
				(isInvalid ||
					(required && !value) ||
					(minLength && value.length < minLength) ||
					(typeof min !== 'undefined' && value < min) ||
					(typeof max !== 'undefined' && value > max)),
			input_classes = ['form-control'],
			label_classes = [];

		input_classes = input_classes.concat(classVals || []);

		if (highlight_invalid) {
			input_classes.push('invalid');
			label_classes.push('invalid');
		}

		if (!onChange) {
			input_classes.push('disabledInput');
			label_classes.push('disabledLabel');
		}

		let input_props = {
			id: name,
			value: value,
			'data-name': label,
			'aria-label': label,
			className: input_classes.join(' '),
			type: type || 'text',
			onBlur: () => {
				this.setState({ hide_invalid: false });
				if (onBlur) onBlur();
			}
		};

		['name', 'placeholder', 'required', 'maxLength', 'minLength', 'step', 'min', 'max'].forEach(key => {
			if (typeof this.props[key] !== 'undefined') input_props[key] = cloneDeep(this.props[key]);
		});

		if (type === 'ssn') input_props.placeholder = 'XXX-XX-XXXX';

		if (onChange) {
			input_props.onChange = e => {
				let val = e.target.value,
					valClean = cleanString(val);

				val = valClean.clean;
				if (valClean.dirty) {
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className='react-confirm-alert'>
									<div className='react-confirm-alert-body'>
										Invalid characters were removed from {`"${label}."` || 'your input.'}
										<div className='react-confirm-alert-button-group'>
											<button onClick={onClose}>OK</button>
										</div>
									</div>
								</div>
							);
						}
					});
				}

				if (type === 'email') val = val.toLowerCase().trim();
				if (type === 'ssn') {
					val = val.replace(/[^0-9.]/g, '');

					if (val.length > 3) val = `${val.substring(0, 3)}-${val.substring(3)}`;
					if (val.length > 6) val = `${val.substring(0, 6)}-${val.substring(6)}`;
					if (val.length > 11) val = val.substring(0, 11);
				}

				onChange(val);
			};
		} else {
			input_props.disabled = true;
		}

		if (disabled) input_props.disabled = true;

		const content = (
			<>
				{!!label && (
					<label htmlFor={name} className={label_classes.join(' ')}>
						{label}
						{note_short && <small>({note_short})</small>}
						{!(required || hide_optional || input_props.disabled) && <small>(Optional)</small>}
						{tooltip}
					</label>
				)}

				{!!note_long && <div className={`text italic${highlight_invalid ? ' invalid' : ''}`}>{note_long}</div>}

				<input {...input_props} />
			</>
		);

		return cols ? (
			<div className={`col-md-${cols}`}>
				<FormGroup>{content}</FormGroup>
			</div>
		) : (
			content
		);
	}
}

Textbox.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
	type: PropTypes.string,
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	note_long: PropTypes.string,
	note_short: PropTypes.string,
	placeholder: PropTypes.string,
	classVals: PropTypes.array,
	required: PropTypes.bool,
	password: PropTypes.bool,
	maxLength: PropTypes.number,
	minLength: PropTypes.number,
	step: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	isInvalid: PropTypes.bool,
	cols: PropTypes.number,
	hide_optional: PropTypes.bool,
	tooltip: PropTypes.object,
	onBlur: PropTypes.func
};
