import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Row, Modal, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import { genders, countries, states, legal_genders } from 'components/modules/opts';
import { cloneDeep, REGEXES } from 'components/modules/_misc';
import { DEFAULT, routeEnabledForModifier } from 'components/modules/app';
import { years_passed } from 'components/modules/date';
import DateInput from 'components/controls/date';
import _svg from 'components/modules/svg';
import RadioList from 'components/controls/radio_list';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import { nextAppPage, prevAppPage } from 'components/modules/nav';
import { UserActions } from 'reducers/user';

class BasicInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show_prev_name_modal: false,
			obj_prev_names: cloneDeep(DEFAULT.OBJ_PREV_NAMES),
			prev_name_index: -1,
			show_ssn_modal: false
		};

		this.show_agent_question = ['FM_I', 'TR_I', 'RE_I', 'RET_I', 'GR_I'].includes(
			this.props.app.application_modifier
		);
	}

	componentDidMount() {
		const { setSubmenu, app, updateApp } = this.props,
			personal_information = cloneDeep(app.personal_information);

		setSubmenu();

		// from before we updated the gender options
		if (['TRAN', 'MAN', 'WOM'].includes(personal_information.gender_identity)) {
			personal_information.gender_identity = '';
			updateApp({ personal_information: personal_information });

			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert'>
							<div className='react-confirm-alert-body'>
								<h1>Expanded Gender Values</h1>
								<p>
									Please review our new, expanded list of options for <strong>Gender</strong>.
								</p>
								<div className='react-confirm-alert-button-group'>
									<button onClick={onClose}>OK</button>
								</div>
							</div>
						</div>
					);
				}
			});
		}
	}

	componentWillUnmount() {
		const { app, updateApp, saveApp } = this.props,
			personal_information = cloneDeep(app.personal_information),
			{ ssn_tin, has_previous_names, citizen_country_code, birth_country_code, working_with_agent } =
				personal_information,
			_valid = this.isValidForm();

		saveApp(_valid);

		if (has_previous_names === 'No') personal_information.previous_names = [];

		if (ssn_tin !== 'US Social Security Number') {
			personal_information.social_security_number = '';
			personal_information.confirm_social_security_number = '';
		}

		if (ssn_tin !== 'US Tax Identification Number') {
			personal_information.taxpayer_identification_number = '';
			personal_information.confirm_taxpayer_identification_number = '';
		}

		if (citizen_country_code !== 'Other') personal_information.country_of_citizenship_other = '';

		if (birth_country_code !== 'Other') personal_information.country_of_birth_other = '';

		if (birth_country_code !== 'USA') personal_information.birth_state = '';

		if (working_with_agent !== 'Yes') personal_information.agent_name = '';

		updateApp({ personal_information: personal_information });
	}

	isValidForm = () => {
		let { app } = this.props,
			{
				has_previous_names,
				previous_names,
				gender,
				date_of_birth,
				citizen_country_code,
				country_of_citizenship_other,
				birth_country_code,
				country_of_birth_other,
				city_of_birth,
				birth_state,
				family_earned_degree,
				working_with_agent,
				agent_name,
				self_graduate_wi_highschool
			} = app.personal_information,
			invalid_dob = !date_of_birth.match(REGEXES.dob);

		if (!invalid_dob) {
			const age = years_passed(date_of_birth);
			if (age < 10 || age > 110) invalid_dob = true;
		}

		return (
			!!has_previous_names &&
			!(has_previous_names === 'Yes' && !previous_names.length) &&
			!!gender &&
			!invalid_dob &&
			this.isValidID() &&
			!!citizen_country_code &&
			!(citizen_country_code === 'Other' && !country_of_citizenship_other) &&
			!!birth_country_code &&
			!(birth_country_code === 'Other' && !country_of_birth_other) &&
			!!city_of_birth &&
			!(birth_country_code === 'USA' && !birth_state) &&
			!!family_earned_degree &&
			!(this.show_agent_question && (!working_with_agent || (working_with_agent === 'Yes' && !agent_name))) &&
			!(!routeEnabledForModifier('/academic-background-hs', app) && !self_graduate_wi_highschool)
		);
	};

	onSavePreviousNames = e => {
		let { updateApp, app } = this.props,
			{ obj_prev_names, prev_name_index } = this.state,
			personal_information = cloneDeep(app.personal_information);

		e.preventDefault();
		e.stopPropagation();

		if (prev_name_index < 0) {
			personal_information.previous_names.push(obj_prev_names);
		} else {
			personal_information.previous_names[prev_name_index] = obj_prev_names;
		}

		updateApp({ personal_information: personal_information });
		this.setState({ prev_name_index: -1, obj_prev_names: cloneDeep(DEFAULT.OBJ_PREV_NAMES) });
	};

	onDeletePreviousName = i => {
		const { app, updateApp } = this.props,
			personal_information = cloneDeep(app.personal_information);

		if (!app.date_submitted) {
			personal_information.previous_names.splice(i, 1);

			updateApp({ personal_information: personal_information });
			this.setState({ obj_prev_names: cloneDeep(DEFAULT.OBJ_PREV_NAMES) });
		}
	};

	onChangePreviousName = (name, value) => {
		const { app } = this.props,
			obj_prev_names = cloneDeep(this.state.obj_prev_names);

		if (!app.date_submitted) {
			obj_prev_names[name] = value;
			this.setState({ obj_prev_names: obj_prev_names });
		}
	};

	isValidID = obj => {
		let { app } = this.props,
			{
				ssn_tin,
				social_security_number,
				confirm_social_security_number,
				taxpayer_identification_number,
				confirm_taxpayer_identification_number
			} = obj || app.personal_information;

		const _valid_confirm_ids = {
			'Do Not Have/Do Not Want to Provide': true,
			'US Social Security Number':
				social_security_number?.length === 11 && confirm_social_security_number === social_security_number,
			'US Tax Identification Number':
				taxpayer_identification_number?.length === 11 &&
				confirm_taxpayer_identification_number === taxpayer_identification_number
		};

		return !!ssn_tin && _valid_confirm_ids[ssn_tin];
	};

	onChange = (prop, val, isValid) => {
		let { app, updateApp } = this.props,
			personal_information = cloneDeep(app.personal_information);

		if (!app.date_submitted) {
			if (prop === 'ssn_tin' && val === 'Do Not Have/Do Not Want to Provide') {
				this.setState({ show_ssn_modal: true });
			}

			personal_information[prop] = val;
			updateApp({ personal_information: personal_information });
		}
	};

	renderPage = () => {
		let { app, first_name, last_name } = this.props,
			{
				middle_name,
				preferred_name,
				suffix,
				has_previous_names,
				previous_names,
				gender,
				gender_identity,
				date_of_birth,
				ssn_tin,
				social_security_number,
				confirm_social_security_number,
				taxpayer_identification_number,
				confirm_taxpayer_identification_number,
				citizen_country_code,
				country_of_citizenship_other,
				birth_country_code,
				country_of_birth_other,
				city_of_birth,
				birth_state,
				family_earned_degree,
				working_with_agent,
				agent_name,
				self_graduate_wi_highschool
			} = app.personal_information,
			invalid_dob = false;

		if (date_of_birth) {
			const age = years_passed(date_of_birth);
			if (age < 10 || age > 110) invalid_dob = true;
		}

		return (
			<>
				<Row>
					<Textbox
						name='first_name'
						value={first_name}
						label='Legal First Name'
						required={true}
						cols={6}
						tooltip={
							<a id='firstNameTooltip'>
								<img
									className={'informationTooltip'}
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>
					<Textbox
						name='last_name'
						value={last_name}
						label='Last Name/Family Name'
						required={true}
						cols={6}
					/>
				</Row>

				<Tooltip
					anchorId='firstNameTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={<p>Your first name on legal documents.</p>}
				/>

				<Row>
					<Textbox
						name='middle_name'
						value={middle_name}
						label='Middle Name'
						placeholder='Middle Name'
						maxLength={40}
						onChange={val => this.onChange('middle_name', val)}
						cols={6}
					/>
					<Textbox
						name='preferred_name'
						value={preferred_name}
						label='Preferred Name/Name in Use'
						placeholder='Enter Preferred First Name'
						maxLength={40}
						onChange={val => this.onChange('preferred_name', val)}
						cols={6}
						tooltip={
							<a id='preferredFirstNameTooltip'>
								<img
									className={'informationTooltip'}
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>
				</Row>

				<Tooltip
					anchorId='preferredFirstNameTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							The name you choose to go by. Some schools use this name for campus communications. To see
							how your school uses this question, see{' '}
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://uwhelp.wisconsin.edu/demographics/'>
								uwhelp.wisconsin.edu/demographics
							</a>
							.
						</p>
					}
				/>

				<Row>
					<Select
						name='suffix'
						value={suffix}
						label='Suffix (Jr, Sr, etc)'
						placeholder='Select Suffix'
						opts={['Jr', 'Sr']}
						cols={6}
						onChange={val => this.onChange('suffix', val)}
					/>
				</Row>

				<RadioList
					name='has_previous_names'
					value={has_previous_names}
					label='Has your name ever changed? (Ex: Life events such as a marriage, adoption, divorce etc)'
					opts={['Yes', 'No']}
					onChange={val => this.onChange('has_previous_names', val)}
					required={true}
					cols={12}
					tooltip={
						<a id='prevNameTooltip'>
							<img
								className={'informationTooltip'}
								src={require('assets/img/Information.png')}
								alt='Information'
							/>
						</a>
					}
				/>

				<Tooltip
					anchorId='prevNameTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							Having all names you have used allows us to match your documents like transcripts that may
							use your old name.
						</p>
					}
				/>

				{has_previous_names === 'Yes' && (
					<Row>
						{!!previous_names.length ? (
							<Button
								bsStyle='info'
								className='back-btn open-sub-form'
								fill
								onClick={() => this.setState({ show_prev_name_modal: true })}>
								Edit previous names
							</Button>
						) : (
							<Button
								bsStyle='info'
								className='back-btn save-btn open-sub-form'
								fill
								onClick={() => this.setState({ show_prev_name_modal: true })}>
								Add previous name
							</Button>
						)}
					</Row>
				)}

				<Row>
					<Select
						name='gender'
						value={gender}
						label='Legal Sex'
						placeholder='Select Legal Sex'
						opts={legal_genders}
						onChange={val => this.onChange('gender', val)}
						required={true}
						cols={6}
						tooltip={
							<a id='legalSexTooltip'>
								<img
									className={'informationTooltip'}
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>
					<Select
						name='gender_identity'
						value={gender_identity}
						label='Gender'
						placeholder='Select Gender'
						opts={genders}
						onChange={val => this.onChange('gender_identity', val)}
						cols={6}
						tooltip={
							<a id='genderTooltip'>
								<img
									className={'informationTooltip'}
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>
				</Row>

				<Tooltip
					anchorId='legalSexTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							This is used for federal reporting. Some schools use this for campus communications. To see
							how your school uses this question, see{' '}
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://uwhelp.wisconsin.edu/demographics/'>
								uwhelp.wisconsin.edu/demographics
							</a>
							.
						</p>
					}
				/>
				<Tooltip
					anchorId='genderTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<>
							<p>Cisgender means you are not transgender.</p>
							<p>
								Some campuses collect this information and some do not. It will not be visible in the
								student directory. To see how your school uses this question, see{' '}
								<a
									target='_blank'
									rel='noopener noreferrer'
									href='https://uwhelp.wisconsin.edu/demographics/'>
									uwhelp.wisconsin.edu/demographics
								</a>
								.
							</p>
						</>
					}
				/>

				<Row>
					<DateInput
						value={date_of_birth}
						label='Date of Birth'
						fullDate={true}
						onChange={(val, isValid) => this.onChange('date_of_birth', val)}
						required={true}
						cols={6}
						isInvalid={invalid_dob}
					/>
				</Row>
				<Row>
					<RadioList
						name='ssn_tin'
						value={ssn_tin}
						label='Which form of identification can you provide?'
						opts={[
							'US Social Security Number',
							'US Tax Identification Number',
							'Do Not Have/Do Not Want to Provide'
						]}
						onChange={val => this.onChange('ssn_tin', val)}
						required={true}
						cols={12}
						forceNewLines={true}
					/>
				</Row>

				{ssn_tin === 'US Social Security Number' && (
					<Row>
						<Textbox
							type='ssn'
							name='social_security_number'
							value={social_security_number}
							label='US Social Security Number'
							onChange={val => this.onChange('social_security_number', val)}
							required={true}
							cols={6}
							isInvalid={social_security_number.length !== 11}
						/>

						<Textbox
							type='ssn'
							name='confirm_social_security_number'
							value={confirm_social_security_number}
							label='Confirm US Social Security Number'
							onChange={val => this.onChange('confirm_social_security_number', val)}
							required={true}
							cols={6}
							isInvalid={social_security_number !== confirm_social_security_number}
						/>
					</Row>
				)}

				{ssn_tin === 'US Tax Identification Number' && (
					<Row>
						<Textbox
							type='ssn'
							name='taxpayer_identification_number'
							value={taxpayer_identification_number}
							label='US Taxpayer Identification Number'
							onChange={val => this.onChange('taxpayer_identification_number', val)}
							required={true}
							cols={6}
							isInvalid={taxpayer_identification_number.length !== 11}
						/>

						<Textbox
							type='ssn'
							name='confirm_taxpayer_identification_number'
							value={confirm_taxpayer_identification_number}
							label='Confirm US Taxpayer Identification Number'
							onChange={val => this.onChange('confirm_taxpayer_identification_number', val)}
							required={true}
							cols={6}
							isInvalid={taxpayer_identification_number !== confirm_taxpayer_identification_number}
						/>
					</Row>
				)}

				<Row>
					<Select
						name='citizen_country_code'
						value={citizen_country_code}
						opts={countries}
						label='Country of Citizenship'
						placeholder='Select Country'
						onChange={val => this.onChange('citizen_country_code', val)}
						required={true}
						cols={6}
					/>

					{citizen_country_code === 'Other' && (
						<Textbox
							name='country_of_citizenship_other'
							value={country_of_citizenship_other}
							label='Specify Country'
							placeholder='Specify Country'
							onChange={val => this.onChange('country_of_citizenship_other', val)}
							maxLength={40}
							required={true}
							cols={6}
						/>
					)}
				</Row>
				<Row>
					<Select
						name='birth_country_code'
						value={birth_country_code}
						label='Country of Birth'
						placeholder='Select Country'
						opts={countries}
						onChange={val => this.onChange('birth_country_code', val)}
						required={true}
						cols={6}
					/>

					{birth_country_code === 'Other' && (
						<Textbox
							name='country_of_birth_other'
							value={country_of_birth_other}
							label='Specify Country'
							placeholder='Specify Country'
							onChange={val => this.onChange('country_of_birth_other', val)}
							maxLength={60}
							required={true}
							cols={6}
						/>
					)}
				</Row>
				<Row>
					<Textbox
						name='city_of_birth'
						value={city_of_birth}
						label='City of Birth'
						placeholder='City of Birth'
						onChange={val => this.onChange('city_of_birth', val)}
						maxLength={50}
						required={true}
						cols={6}
					/>

					{(birth_country_code || 'USA') === 'USA' && (
						<Select
							name='birth_state'
							value={birth_state}
							label='State of Birth'
							placeholder='Select State'
							opts={states}
							onChange={val => this.onChange('birth_state', val)}
							required={true}
							cols={6}
						/>
					)}
				</Row>
				<Row>
					<RadioList
						name='family_earned_degree'
						value={family_earned_degree}
						label='Have either of your parents and/or guardians earned a four-year college/university degree?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('family_earned_degree', val)}
						required={true}
						cols={12}
						tooltip={
							<a id='familyDegreeTooltip'>
								<img
									className={'informationTooltip'}
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>
				</Row>

				<Tooltip
					anchorId='familyDegreeTooltip'
					className='tooltipContainer'
					delayHide={1000}
					effect='solid'
					content={
						<p>
							Please reply &apos;yes&apos; to this question if any of your parents and/or guardians have
							completed a bachelors degree (or other 4-year degree) or higher.
						</p>
					}
				/>

				{this.show_agent_question && (
					<>
						<Row>
							<RadioList
								name='working_with_agent'
								value={working_with_agent}
								label='Are you working with an agent or advisor?'
								opts={['Yes', 'No']}
								onChange={val => this.onChange('working_with_agent', val)}
								required={true}
								cols={12}
								tooltip={
									<a id='agentTooltip'>
										<img
											className={'informationTooltip'}
											src={require('assets/img/Information.png')}
											alt='Information'
										/>
									</a>
								}
							/>
						</Row>

						<Tooltip
							anchorId='agentTooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<p>
									An agent, also known as an advisor or educational consultant, assists you with
									completing applications for admission.
								</p>
							}
						/>

						{working_with_agent === 'Yes' && (
							<Row>
								<Textbox
									name='agent_name'
									value={agent_name}
									label='Agency or advisor name'
									placeholder='Agency or advisor name'
									onChange={val => this.onChange('agent_name', val)}
									maxLength={50}
									required={true}
									cols={6}
								/>
							</Row>
						)}
					</>
				)}

				{/*FOR ROUTES WHERE HS IS NOT ENABLED*/}
				{!routeEnabledForModifier('/academic-background-hs', app) && (
					<Row>
						<RadioList
							name='self_graduate_wi_highschool'
							value={self_graduate_wi_highschool}
							label='Did you graduate from a Wisconsin high school?'
							opts={['Yes', 'No']}
							onChange={val => this.onChange('self_graduate_wi_highschool', val)}
							required={true}
							cols={12}
						/>
					</Row>
				)}
			</>
		);
	};

	renderSSNModal = () => {
		const { show_ssn_modal } = this.state;

		return (
			<Modal show={show_ssn_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Why you should provide a TIN OR SSN</Modal.Title>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<p className={'text'}>
						You are strongly encouraged to provide a Social Security Number (SSN) or other Taxpayer
						Identification Number (TIN) if you have one. If you have both, enter ONLY your SSN. Failure to
						submit a SSN or TIN could have the following consequences:
					</p>
					<ul>
						<li className={'text'}>
							A delay in the awarding of financial aid{' '}
							<b>(NOTE: international students are not eligible for state and federal financial aid)</b>
						</li>
						<li className={'text'}>
							An inability to claim tax benefits on your and/or your parents&#39; tax returns for the
							tuition and fees you pay to attend college; and
						</li>
						<li className={'text'}>
							If you are a U.S. citizen or a permanent resident for U.S. tax purposes, the IRS may require
							you to pay a $50 penalty.
						</li>
					</ul>

					<p className={'text'}>
						While you are not legally required to provide your SSN or TIN for the purposes of this
						application, you will be required to do so if you attend a UW institution and you are a U.S.
						citizen or a permanent resident for tax purposes.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ show_ssn_modal: false })}>
						Close and Accept
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderPreviousNamesTable() {
		const { app } = this.props,
			{ previous_names } = app.personal_information;

		return previous_names.length
			? previous_names.map((objName, i) => {
					const { first_name, middle_name, last_name } = objName;

					return (
						<tr key={`activity${i}`}>
							<td data-label='First Name'>{first_name}</td>
							<td data-label='Middle Name'>{middle_name}</td>
							<td data-label='Last Name'>{last_name}</td>
							<td data-label='Actions'>
								<Button
									bsStyle='info'
									onClick={() => {
										this.setState({
											obj_prev_names: cloneDeep(app.personal_information.previous_names[i]),
											prev_name_index: i
										});
									}}
									className='editBtn btn-xs'>
									<img
										alt='editIcon'
										className={'imgDashboard'}
										height={'18px'}
										width={'18px'}
										src={_svg.Edit}
									/>
									<p className={'pDashboard'}>Edit</p>
								</Button>

								<Button
									bsStyle='info'
									onClick={() => this.onDeletePreviousName(i)}
									className='deleteBtn btn-xs'>
									<img
										alt='deleteIcon'
										className={'imgDashboard'}
										height={'18px'}
										width={'18px'}
										src={_svg.Delete}
									/>
									<p className={'pDashboard'}>Delete</p>
								</Button>
							</td>
						</tr>
					);
			  })
			: [
					<tr key={'none'}>
						<td colSpan={4}>You have no previous names added.</td>
					</tr>
			  ];
	}

	renderPrevNamesModal = () => {
		const { show_prev_name_modal, obj_prev_names } = this.state,
			{ first_name, middle_name, last_name } = obj_prev_names,
			valid_table = !!first_name && !!last_name;

		return (
			<Modal show={show_prev_name_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Previous Names</Modal.Title>
				</Modal.Header>
				<Modal.Body className={'modalMinHeight'}>
					<form id={'prev-name-form'} onSubmit={this.onSavePreviousNames}>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th scope='col'>
										<b>First Name</b>
									</th>
									<th scope='col'>
										<b>Middle Name</b>
									</th>
									<th scope='col'>
										<b>Last Name</b>
									</th>
									<th scope='col'>
										<b>Actions</b>
									</th>
								</tr>
							</thead>
							<tbody>{this.renderPreviousNamesTable()}</tbody>
						</Table>

						<hr />

						<Row>
							<Textbox
								name='first_name'
								value={first_name}
								label='Legal First Name'
								required={true}
								maxLength={75}
								onChange={val => this.onChangePreviousName('first_name', val)}
								cols={4}
							/>
							<Textbox
								name='middle_name'
								value={middle_name}
								label='Middle Name'
								maxLength={75}
								onChange={val => this.onChangePreviousName('middle_name', val)}
								cols={4}
							/>
							<Textbox
								name='last_name'
								value={last_name}
								label='Last Name'
								required={true}
								maxLength={75}
								onChange={val => this.onChangePreviousName('last_name', val)}
								cols={4}
							/>
						</Row>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='back-btn'
						fill
						onClick={() => this.setState({ show_prev_name_modal: false })}>
						Close
					</Button>
					<Button
						form={'prev-name-form'}
						bsStyle='info'
						className='save-btn'
						type={'submit'}
						disabled={!valid_table}
						fill>
						Save Name
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	render() {
		const { navigate, location, app } = this.props,
			_valid = this.isValidForm();

		return (
			<Card
				title={'BASIC INFORMATION'}
				content={
					<form id='main-form' onSubmit={() => navigate(nextAppPage(app, location.pathname))}>
						{this.renderPage()}
						{this.renderSSNModal()}
						{this.renderPrevNamesModal()}

						<div className='clearfix' />
					</form>
				}
				buttons={
					<>
						<Button
							aria-label='Back'
							bsStyle='info'
							className='back-btn'
							fill
							onClick={() => navigate(prevAppPage(app, location.pathname))}>
							Back
						</Button>
						<Button
							form='main-form'
							aria-label='Save and Continue'
							bsStyle='info'
							className='save-btn'
							fill
							disabled={!_valid}
							type={'submit'}>
							Save and Continue
						</Button>
					</>
				}
			/>
		);
	}
}

const mapStateToProps = state => {
		const { app_id, apps, first_name, last_name } = state.user;

		return {
			first_name: first_name,
			last_name: last_name,
			apps: apps,
			app: apps[app_id].json_obj,
			app_id: app_id
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		saveApp: val => dispatch(UserActions.saveApp({ basicInformationCompleted: val })),
		setSubmenu: () => dispatch(UserActions.setSubmenus('personal_info_open', true))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicInformation));
