import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import withRouter from 'components/Wrappers/withRouter';
import { NoSidebarCard } from 'components/Card/NoSidebarCard';
import InitialInfoCard from 'components/Card/InitialInfoCard';
import Select from 'components/controls/select';
import Button from 'components/CustomButton';
import { create_proactive } from 'components/modules/ada';
import { cloneDeep } from 'components/modules/_misc';
import { APP_TYPES, DEFAULT, resolveModifier } from 'components/modules/app';
import _svg from 'components/modules/svg';
import { UserActions } from 'reducers/user';

class SelectAppModifier extends Component {
	constructor(props) {
		super(props);

		this.state = {
			page: props.location.state?.page || 0,
			res_modal: false
		};

		this.orig_modifier = `${props.app.application_modifier}`;
	}

	componentDidMount() {
		this._mounted = true;

		const { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information);

		setTimeout(() => {
			if (this._mounted) create_proactive('Need help getting started?');
		}, 2000);

		const from_direct_admit = !!this.props.location.state?.from_direct_admit || false;
		if (from_direct_admit) {
			delete initial_information.direct_admit;
			initial_information.chosen_campus = {};
			initial_information.chosen_semester = {};

			updateApp({ application_modifier: '', initial_information: initial_information });
		}
	}

	componentDidUpdate(prevProps) {
		const { bypass_app_type, navigate } = this.props;
		if (!bypass_app_type && prevProps.bypass_app_type) navigate('/select-campus');
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	toUndergradSelection = e => {
		let { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information);

		initial_information.app_type_question_5 = '';
		initial_information.app_type_question_6 = '';
		initial_information.app_type_question_7 = '';

		updateApp({ initial_information: initial_information });
		this.setState({ page: 7 });
	};

	onChangeInfoCard = obj => {
		let { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information);

		if (!app.date_submitted) {
			initial_information[obj.stateVariable] = obj.value;

			updateApp({ initial_information: initial_information });
			this.saveAndContinue(initial_information); //Advance screens
		}
	};

	saveAndContinue = (initial_information, e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		let {
				app,
				updateApp,
				navigate,
				setScreensCompleted,
				obj_bypass,
				bypass_app_type,
				removeBypassObject,
				creating_additional_app
			} = this.props,
			page = 0 + this.state.page,
			application_modifier = `${app.application_modifier}`;

		if (!initial_information) initial_information = cloneDeep(app.initial_information);

		if (page === 2 && initial_information.us_citizenship_status === 'Yes') {
			page = 6;
		} else if (page === 3 && initial_information.alien_status === 'Other') {
			this.setState({ res_modal: true });
		} else if (
			page === 3 &&
			['Refugee', 'Resident Alien', 'Applying Alien', 'Intl Online'].includes(initial_information.alien_status)
		) {
			page = 6; // non-citizen but still domestic application
		} else if (page === 3 && initial_information.alien_status === 'Requesting Visa') {
			// requesting visa, don't need to ask if needs visa.
			initial_information.need_f1_j1 = 'Yes';
			page = 6;
		} else if (
			page === 4 &&
			['95762', '95776'].includes(initial_information.visa_type) &&
			initial_information.studentPlan === 'Degree'
		) {
			// currently have a F-1/J-1 - will need a new one from new campus
			initial_information.need_f1_j1 = 'Yes';
			page = 6;
		} else {
			page++;
		}

		const move_on = !!application_modifier;
		if (application_modifier) {
			let screens_completed_arg = { initialInformationCompleted: true };

			// Force student back through campus if modifier changes
			if (application_modifier !== this.orig_modifier) {
				screens_completed_arg.selectCampusCompleted = false;
				screens_completed_arg.selectAreaStudyCompleted = false;
				screens_completed_arg.selectSemesterCompleted = false;
				screens_completed_arg.summaryCompleted = false;

				initial_information.chosen_campus = {};
				initial_information.chosen_campusError = '';
				initial_information.re_entry_attended_campus = '';
				initial_information.re_entry_attended_other = '';

				updateApp({ area_of_study: cloneDeep(DEFAULT.APP_JSON.area_of_study) });

				if (!creating_additional_app) {
					initial_information.chosen_semester = { id: '', title: '', global_term: '' };
				}
			}

			setScreensCompleted(screens_completed_arg);
		} else {
			let modifier = resolveModifier(initial_information);
			application_modifier = modifier.modifier;
			page = modifier.page || page;
		}

		if (move_on && bypass_app_type) {
			if (bypass_app_type === application_modifier.replace('RE_', 'RET_')) application_modifier = bypass_app_type;

			if (bypass_app_type === application_modifier) {
				initial_information.chosen_campus = obj_bypass.field_campus;
				initial_information.chosen_campus.disabled = false;
			}
		}

		updateApp({ application_modifier: application_modifier, initial_information: initial_information });
		this.setState({ page: page });

		if (move_on) {
			if (bypass_app_type) {
				if (bypass_app_type === application_modifier) {
					navigate('/course-of-study');
				} else {
					confirmAlert({
						customUI: ({ onClose }) => {
							return (
								<div className='react-confirm-alert'>
									<div className='react-confirm-alert-body'>
										<h1>Mismatching app type</h1>
										<p>
											Your term bypass link specifies an application type of &apos;
											<strong>{APP_TYPES[bypass_app_type]}</strong>&apos;, but you&apos;re
											attempting to create an application of type &apos;
											<strong>{APP_TYPES[application_modifier]}</strong>&apos;.
										</p>
										<p>Your term bypass will not be honored if you continue.</p>
										<div className='react-confirm-alert-button-group'>
											<button
												onClick={() => {
													removeBypassObject();
													navigate('/select-campus');

													onClose();
												}}>
												Continue
											</button>
											<button
												onClick={() => {
													updateApp({
														application_modifier: '',
														initial_information: cloneDeep(
															DEFAULT.APP_JSON.initial_information
														)
													});

													onClose();
												}}>
												Oops! I&apos;ll change it
											</button>
										</div>
									</div>
								</div>
							);
						}
					});
				}
			} else {
				navigate('/select-campus');
			}
		}
	};

	onBack = e => {
		let { app, updateApp, navigate } = this.props,
			page = 0 + this.state.page,
			application_modifier = `${app.application_modifier}`,
			initial_information = cloneDeep(app.initial_information);

		if (application_modifier) {
			// tms:  start the whole application modifier process over, but don't touch the other init info values yet
			application_modifier = '';
			page = 0;
			initial_information.studentPlan = '';

			for (let i = 4; i <= 7; i++) {
				initial_information[`app_type_question_${i}`] = '';
			}
		} else {
			if (page === 6 && initial_information.us_citizenship_status === 'Yes') {
				page = 2;
			} else if (
				page === 6 &&
				['Refugee', 'Resident Alien', 'Applying Alien', 'Intl Online', 'Other'].includes(
					initial_information.alien_status
				)
			) {
				page = 3;
			} else if (page === 6 && initial_information.alien_status === 'Requesting Visa') {
				initial_information.need_f1_j1 = '';
				page = 3;
			} else if (
				page === 6 &&
				['95762', '95776'].includes(initial_information.visa_type) &&
				initial_information.studentPlan === 'Degree'
			) {
				initial_information.need_f1_j1 = '';
				page = 4;
			} else if (
				page === 9 &&
				initial_information.app_type_question_5 === "I've earned a bachelor's degree or higher"
			) {
				page = 7; //Grad logic
			} else {
				page--;
			}
		}

		//Forget state on back
		if (page >= 6) {
			let app_type_question = `app_type_question_${page - 2}`;
			initial_information[app_type_question] = '';
		}

		if (page < 0) {
			page = 0;
			navigate('/my-account');
		}

		updateApp({ application_modifier: application_modifier, initial_information: initial_information });
		this.setState({ page: page });
	};

	onSelectResideInUS = obj => {
		const { app, updateApp } = this.props,
			initial_information = cloneDeep(app.initial_information);

		if (!app.date_submitted) {
			initial_information.reside_in_us = obj.value;
			updateApp({ initial_information: initial_information });
		}

		this.setState({ res_modal: false, page: 6 });
	};

	renderModal() {
		let { res_modal } = this.state;

		return (
			<Modal show={res_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Current Residency</Modal.Title>
				</Modal.Header>
				<Modal.Body className='modalMinHeight'>
					<form className='text-center'>
						<p>Do you currently reside in the United States?</p>

						<InitialInfoCard
							cards={[
								{
									display: 'Yes',
									image: _svg.InitInfoUS,
									value: 'Yes'
								},
								{
									display: 'No',
									image: _svg.InitInfoGlobe,
									value: 'No'
								}
							]}
							stateVariable='reside_in_us'
							onChange={this.onSelectResideInUS}
						/>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ res_modal: false })}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	render() {
		let { app, navigate, visa_types, removeBypassObject, bypass_app_type } = this.props,
			{ page } = this.state,
			campusPage,
			header = '',
			cardInfo = [],
			no_spacing = false;

		if (page === 0) {
			campusPage = (
				<>
					<p className='infoSidebarParagraph white'>
						Universities of Wisconsin campuses have a variety of application types.
					</p>

					<p className='infoSidebarParagraph white'>
						We&apos;ll start with a few questions so that we can pick the best application for you.
					</p>
				</>
			);
		} else if (page === 1) {
			header = 'Are you applying as a degree-seeking student?';
			no_spacing = true;

			cardInfo = [
				{
					display: 'Yes',
					image: _svg.InitInfoYes,
					value: 'Degree',
					extraClass: 'textImages',
					tabIndex: '1'
				},
				{
					display: 'No',
					image: _svg.InitInfoNo,
					value: 'Few Courses',
					extraClass: 'textImages',
					tabIndex: '2'
				}
			];

			campusPage = (
				<>
					<p className='degree-seeking-expl'>
						A degree-seeking student plans to work towards a degree (e.g., Associates, Bachelors, Masters,
						etc.) at the campus at which they enroll.
						<a id='tooltip'>
							<img
								className='informationTooltip'
								src={require('assets/img/Information.png')}
								alt='Information'
							/>
						</a>
					</p>

					<Tooltip
						anchorId='tooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={
							<p>
								Answer no if you wish to temporarily enroll to take a few courses and/or are a high
								school dual-enrollment student.
							</p>
						}
					/>

					<InitialInfoCard cards={cardInfo} stateVariable='studentPlan' onChange={this.onChangeInfoCard} />
				</>
			);
		} else if (page === 2) {
			header = 'Are you a U.S. Citizen?';
			cardInfo = [
				{
					display: 'Yes',
					image: _svg.InitInfoUS,
					value: 'Yes',
					extraClass: 'citizenImages',
					tabIndex: '1'
				},
				{
					display: 'No',
					image: _svg.InitInfoGlobe,
					value: 'No',
					extraClass: 'citizenImages',
					tabIndex: '2'
				}
			];

			campusPage = (
				<>
					<InitialInfoCard
						cards={cardInfo}
						stateVariable='us_citizenship_status'
						onChange={this.onChangeInfoCard}
					/>
				</>
			);
		} else if (page === 3) {
			header = 'U.S. Residency Status';

			let visaOptions = [],
				visas = [
					{
						value: 'Refugee',
						display: 'U.S. Refugee/Granted Political Asylum'
					},
					{
						value: 'Resident Alien',
						display: 'U.S. Permanent Resident'
					},
					{
						value: 'Applying Alien',
						display: 'Applied/Applying for U.S. Permanent Resident Status'
					},
					{
						value: 'Non immigrant Alien',
						display: 'Current Visa Holder (residing in U.S.)'
					},
					{
						value: 'Requesting Visa',
						display: 'Need to Apply for Student Visa (not residing in U.S.)'
					},
					{
						value: 'Intl Online',
						display: 'International Student Applying to an Online Program'
					},
					{
						value: 'Other',
						display: 'Other'
					}
				];

			visas.forEach((visa, i) => {
				let onChangePayload = { stateVariable: 'alien_status', value: visa.value },
					handleKeyPress = this.handleKeyPress;

				handleKeyPress = e => {
					if (e.key === 'Enter') this.onChangeInfoCard(onChangePayload);
				};

				visaOptions.push(
					<div
						tabIndex='0'
						key={`visa_opt_${i}`}
						onKeyPress={handleKeyPress}
						onClick={() => this.onChangeInfoCard(onChangePayload)}
						className='visaContainer test'>
						<p className='visaText'>{visa.display}</p>
					</div>
				);
			});

			campusPage = (
				<div className='col-md-10 col-centered'>
					<p>What is your current status?</p>
					{visaOptions}
				</div>
			);
		} else if (page === 4) {
			header = 'Visa Type';

			campusPage = (
				<Select
					value={app.initial_information.visa_type}
					opts={visa_types.map(rec => ({ val: rec.id, label: rec.title }))}
					onChange={val => {
						let { app, updateApp } = this.props,
							initial_information = cloneDeep(app.initial_information);

						if (!app.date_submitted) {
							initial_information.visa_type = val;
							updateApp({ initial_information: initial_information });
							this.saveAndContinue(initial_information); //Advance screens
						}
					}}
					name='visa_type'
					placeholder='Select Visa Type'
					cols={8}
					centered={true}
				/>
			);
		} else if (page === 5) {
			header = 'Do you need a visa to study in the United States?';
			cardInfo = [
				{
					display: 'Yes',
					image: _svg.InitInfoYes,
					value: 'Yes',
					extraClass: 'textImages',
					tabIndex: '1'
				},
				{
					display: 'No',
					image: _svg.InitInfoNo,
					value: 'No',
					extraClass: 'textImages',
					tabIndex: '2'
				}
			];

			campusPage = (
				<InitialInfoCard cards={cardInfo} stateVariable='need_f1_j1' onChange={this.onChangeInfoCard} />
			);
		} else if (page === 6) {
			header = 'Have you graduated from high school/secondary school or earned your GED/HSED yet?';

			cardInfo = [
				{
					display: 'Yes',
					image: _svg.InitInfoYes,
					value: 'Yes',
					extraClass: 'textImages',
					tabIndex: '1'
				},
				{
					display: 'No',
					image: _svg.InitInfoNo,
					value: 'No',
					extraClass: 'textImages',
					tabIndex: '2'
				}
			];

			campusPage = (
				<InitialInfoCard
					cards={cardInfo}
					stateVariable='app_type_question_4'
					onChange={this.onChangeInfoCard}
				/>
			);
		} else if (page === 7) {
			header = 'Do you have any college/post-secondary experience?';
			cardInfo = [
				{
					display: "I've not attended college or I took college courses in high school",
					image: require('assets/img/ApplicantType/freshman.png'),
					value: "I've not attended college or I took college courses in high school",
					tabIndex: '1'
				},
				{
					display: "I've attended some college",
					image: require('assets/img/ApplicantType/ReEntry.png'),
					value: "I've attended some college",
					tabIndex: '2'
				},
				{
					display: "I have earned or will earn a bachelor's degree or higher before I enroll",
					image: require('assets/img/ApplicantType/2ndDegree.png'),
					value: "I've earned a bachelor's degree or higher",
					tabIndex: '3'
				}
			];

			if (app.initial_information.studentPlan === 'Few Courses') {
				header = 'Do you plan to take undergraduate or graduate classes?';
				cardInfo = [
					{
						display: 'Undergraduate',
						image: require('assets/img/InitialInformation/Undergrad.png'),
						value: 'Undergraduate',
						tabIndex: '1'
					},
					{
						display: 'Graduate',
						image: require('assets/img/InitialInformation/Graduate.png'),
						value: 'Graduate',
						tabIndex: '2'
					}
				];
			}

			campusPage = (
				<InitialInfoCard
					cards={cardInfo}
					stateVariable='app_type_question_5'
					onChange={this.onChangeInfoCard}
				/>
			);
		} else if (page === 8) {
			header =
				'Are you returning to the Universities of Wisconsin campus that you attended before as a degree seeking student?';

			if (app.initial_information.studentPlan === 'Few Courses') {
				header = "Do you have a bachelor's degree or will you have one by the time of the course?";
			}

			cardInfo = [
				{
					display: 'Yes',
					image: _svg.InitInfoYes,
					value: 'Yes',
					extraClass: 'textImages',
					tabIndex: '1'
				},
				{
					display: 'No',
					image: _svg.InitInfoNo,
					value: 'No',
					extraClass: 'textImages',
					tabIndex: '2'
				}
			];

			campusPage = (
				<InitialInfoCard
					cards={cardInfo}
					stateVariable='app_type_question_6'
					onChange={this.onChangeInfoCard}
				/>
			);
		} else if (page === 9) {
			if (app.initial_information.studentPlan === 'Few Courses') {
				campusPage = (
					<div className='col-md-10 col-centered'>
						<h2 className='bold'>
							To take graduate classes you need to have a bachelor’s degree prior to the class starting.
						</h2>
						<br />
						<p>
							You are eligible to take undergraduate classes. If you have questions please contact the
							campus you plan to apply to.
						</p>
					</div>
				);
			} else {
				header = "Are you looking to earn a bachelor's degree or a graduate degree?";
				cardInfo = [
					{
						display: "Bachelor's Degree",
						image: require('assets/img/ApplicantType/ReEntry.png'),
						value: "Bachelor's Degree",
						tabIndex: '1'
					},
					{
						display: 'Graduate Degree',
						image: require('assets/img/ApplicantType/2ndDegree.png'),
						value: 'Graduate Degree',
						tabIndex: '2'
					}
				];

				campusPage = (
					<InitialInfoCard
						cards={cardInfo}
						stateVariable='app_type_question_7'
						onChange={this.onChangeInfoCard}
					/>
				);
			}
		} else {
			campusPage = <h2>You picked the wrong path. link to go back</h2>;
		}

		//Overwrite show modifier
		if (app.application_modifier) {
			let app_type = app.application_modifier.split('_')[0],
				app_type_text = '',
				app_type_info = '',
				second_degree =
					app.initial_information.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
					app.initial_information.app_type_question_7 === "Bachelor's Degree";

			switch (app_type) {
				case 'FM':
					app_type_text = 'You are a Freshman Applicant';
					app_type_info =
						'A freshman applicant is an individual currently in high school/secondary school or has graduated from high school/secondary school. This is their first time enrolling in college. This individual is still considered a freshman applicant even if college classes were taken while completing high school.';

					break;
				case 'TR':
					app_type_text = second_degree
						? 'You are a Transfer/2nd Degree Applicant'
						: 'You are a Transfer Applicant';

					app_type_info = second_degree
						? "A Transfer/2nd Degree applicant holds a bachelor's degree and is looking to complete a second undergraduate degree."
						: 'A transfer applicant is an individual who has attended another college or university after graduating from high school and/or earning a GED/HSED.';

					break;
				case 'RE':
					app_type_text = second_degree
						? 'You are a Re-Entry/2nd Degree Applicant'
						: 'You are a Re-Entry Applicant';

					app_type_info = second_degree
						? "A re-entry/2nd degree applicant holds a bachelor's degree and is looking to complete a second undergraduate degree at the same campus."
						: 'A re-entry applicant is an individual who is returning to a campus they previously attended as a degree-seeking student to complete their undergraduate degree.';

					break;
				case 'RET':
					app_type_text = second_degree
						? 'You are a Re-Entry Transfer/2nd Degree Applicant'
						: 'You are a Re-Entry Transfer Applicant';

					app_type_info =
						'A re-entry transfer applicant is an individual who is returning to a campus they previously attended as a degree-seeking student to complete their undergraduate degree.';

					break;
				case 'GR':
					//Special?
					if (app.application_modifier.split('_').length > 2) {
						app_type_text = 'You are a Graduate Non-Degree Applicant';
						app_type_info =
							'A Graduate Non-Degree applicant has or will have a bachelor’s degree (by the time of the course start) and would like to take a graduate level course or two.';
					} else {
						app_type_text = 'You are a Graduate Applicant';
						app_type_info =
							'A graduate applicant holds a bachelor’s degree and is seeking a master’s degree or higher.';
					}

					break;
				case 'HS':
					app_type_text = 'You are a High School Non-Degree Applicant';
					app_type_info =
						'A High School Non-Degree applicant is in high school and plans on taking a college course or two.';

					break;
				case 'UG':
					app_type_text = 'You are an Undergraduate Non-Degree Applicant';
					app_type_info =
						'An Undergraduate Non-Degree applicant has graduated from high school or earned a GED/HSED and would like to take a college course or two';

					break;
				default:
					app_type_text = 'Unknown app type case';
					app_type_info = 'Your app modifier is: ' + app.application_modifier;
			}

			header = app_type_text;
			campusPage = (
				<div className='text-center'>
					<p className='infoSidebarParagraph'>{app_type_info}</p>
				</div>
			);
		}

		let myAccountButton = (
				<Button
					tabIndex='90'
					bsStyle='info'
					className='tertiaryButton'
					fill
					onClick={() => {
						if (bypass_app_type) {
							confirmAlert({
								title: 'Drop Term Bypass?',
								message: 'This will forgo the term bypass link you were provided.  Continue?',
								buttons: [
									{
										label: 'Yes',
										onClick: () => {
											removeBypassObject();
											navigate('/my-account');
										}
									},
									{
										label: 'No'
									}
								]
							});
						} else {
							navigate('/my-account');
						}
					}}>
					{bypass_app_type ? 'Exit' : 'My Account'}
				</Button>
			),
			buttons = (
				<>
					{page > 0 && (
						<Button tabIndex='91' bsStyle='info' className='back-btn' fill onClick={this.onBack}>
							Back
						</Button>
					)}

					{myAccountButton}

					{(!page || !!app.application_modifier) && (
						<Button tabIndex='92' form='form' bsStyle='info' className='save-btn' fill type='submit'>
							continue
						</Button>
					)}
				</>
			);

		//Custom stop
		if (app.initial_information.studentPlan === 'Few Courses' && page === 9) {
			buttons = (
				<>
					{myAccountButton}

					<Button
						tabIndex='93'
						form='form'
						bsStyle='info'
						className='save-btn'
						fill
						onClick={this.toUndergradSelection}>
						Undergrad/Grad selection
					</Button>
				</>
			);
		}

		return (
			<NoSidebarCard
				header={header}
				no_spacing={no_spacing}
				content={
					<form id='form' onSubmit={e => this.saveAndContinue(null, e)} className='text-center'>
						{this.renderModal()}
						{campusPage}
						<div className='clearfix' />
					</form>
				}
				buttons={buttons}
			/>
		);
	}
}

const mapStateToProps = state => {
		return {
			visa_types: state.global.visa_types,
			app: state.user.temp_app_rec.json_obj,
			obj_bypass: state.user.obj_bypass,
			bypass_app_type: state.user.obj_bypass.field_application_type,
			creating_additional_app: state.user.creating_additional_app
		};
	},
	mapDispatchToProps = dispatch => ({
		updateApp: obj => dispatch(UserActions.updateApp(obj)),
		setScreensCompleted: arg => dispatch(UserActions.setScreensCompleted(arg)),
		removeBypassObject: () => dispatch(UserActions.applyBypassObject())
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectAppModifier));
