import _ from 'underscore';

import routes from 'components/routes';
import { cloneDeep, optsTerritory, optsBadState } from 'components/modules/_misc';
import { strNow, months_passed, years_passed } from 'components/modules/date';
import { GET_LABEL } from 'components/modules/programs';
import _svg from 'components/modules/svg';
import { FIND_TERM, getTermStartDate } from 'components/modules/term';
import { IS } from 'components/modules/programs';

const default_app_json = {
		parent_application_id: -1, //Links multiple applications together when applying to additional campuses
		application_modifier: '', //Determined in initial app selector. Can be any of: "FM_D", "FM_I", "TR_D", "TR_I", "RE_D", "RE_I", "RET_D", "RET_I", "GR_D", "GR_I", "HS_D_S", "UG_D_S", "UG_I_S", "GR_D_S", "GR_I_S"
		percentage_complete: 0, //Sidebar value
		term_bypassed: '',

		//Used for showing progress complete in sidebar and for progressing through the app via the next uncompleted route.
		screensCompleted: {
			initialInformationCompleted: false,
			selectCampusCompleted: false,
			selectSemesterCompleted: false,
			selectAreaStudyCompleted: false,
			summaryCompleted: false,

			personalInformationCompleted: false,
			basicInformationCompleted: false,
			personalInformationAdditionalCompleted: false,
			contactInfoCompleted: false,
			parentInfoCompleted: false,
			residencyCompleted: false,

			academicBackgroundCompleted: false,
			academicBackgroundHSCompleted: false,
			academicBackgroundHSCoursesCompleted: false,
			academicBackgroundTestScoresCompleted: false,
			academicBackgroundHigherEdCheckCompleted: false,
			academicBackgroundCollegeCompleted: false,
			academicBackgroundCollegeCoursesCompleted: false,

			holisticBackgroundCompleted: false,
			activitiesCompleted: false,
			employmentCompleted: false,
			essayCompleted: false,

			//SUBMIT
			submit: false,
			additional: false,
			sign: false,
			waiver: false,
			payment: false,
			nextSteps: false
		},

		//Sidebar progress bar value
		progressBar: {
			percentage: 0
		},

		//Sidebar submenus open or closed
		submenus: {
			academic_background_open: false,
			personal_info_open: false,
			my_account_open: false,
			submit_open: false,
			holistic_background_open: false
		},

		//Initial pages of the app. Houses app modifier info, student type, campus, semester
		initial_information: {
			//SELECT APP MODIFIER:
			studentPlan: '',
			us_citizenship_status: '',
			alien_status: '',
			reside_in_us: '',
			visa_type: '',
			need_f1_j1: '',

			app_type_question_4: '',
			app_type_question_5: '',
			app_type_question_6: '',
			app_type_question_7: '',

			chosen_campus: {},
			chosen_semester: {
				id: '',
				title: '',
				global_term: ''
			},

			re_entry_attended_campus: '',
			re_entry_attended_other: '',

			//Pass user platform info for support:
			platform_browser: '',
			platform_os: '',
			user_agent: '',

			direct_admit: {}
		},

		//Area of student and selected major information
		area_of_study: {
			choose_program_by_option: '',
			selected_career_cluster: {},

			selected_primary_1: {},
			selected_primary_1_delivery_method: '',

			selected_primary_2: {},
			selected_primary_2_delivery_method: ''
		},

		//Personal information information. Houses name, ssn, family info, etc
		personal_information: {
			preferred_name: '',
			middle_name: '',
			has_previous_names: '',
			previous_names: [],
			suffix: '',
			gender: '',
			gender_identity: '',
			date_of_birth: '',

			ssn_tin: '',

			social_security_number: '',
			confirm_social_security_number: '',

			taxpayer_identification_number: '',
			confirm_taxpayer_identification_number: '',

			citizen_country_code: '',
			country_of_citizenship_other: '',
			city_of_birth: '',
			birth_state: '',
			birth_country_code: '',
			country_of_birth_other: '',

			military_service: [],
			veteran_status: [],
			family_earned_degree: '',
			working_with_agent: '',
			agent_name: '',
			self_graduate_wi_highschool: '',
			heritage_hispanic: '',
			selected_ethnicities: [],
			heritage_race: [],
			heritage_tribe_code: '',
			other_tribe: '',
			tribal_enrollment_no: '',

			waive_fee: '',
			waive_fee_reason: '',
			waive_fee_accepted_warning: ''
		},

		//Parent & relationship information. parents array of objects. Object model found in ParentInfo.tsx
		parent_info: {
			parents: []
		},

		//Contact and mailing information
		contact_information: {
			permanent_address: {
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				other_state: '',
				county: '',
				zip: '',
				country: '',
				formatted_address: '',
				formatted_address_correct: '',
				addr_validated: false
			},
			receive_mail_at_permanent_address: '',
			mailing_address: {
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				other_state: '',
				county: '',
				zip: '',
				country: '',
				formatted_address: '',
				formatted_address_correct: '',
				addr_validated: false
			},
			other_phone_type: '',
			other_phone_number: '',
			preferred_phone_number: ''
		},

		//Highschool and college information. Models for each array of objects can be found in components.
		academic_background: {
			highschools: [],

			//HS Courses
			hs_course_use_previous_file: '',
			hs_course_schedule_file: '',
			hs_course_schedule_file_name: '',
			any_dual_enrollment: '',
			hs_show_courses_option: '',
			courses: [],

			//Test scores
			act_transition_option: '',
			self_report_scores: '',
			report_future_tests: '',
			test_scores: [],

			//Colleges
			colleges: [],

			//College courses
			college_show_courses_option: '',
			college_course_use_previous_file: '',
			college_course_schedule_file: '',
			college_course_schedule_file_name: '',
			college_courses_in_progress: []
		},

		//Activities and employment. Employment and activities model found in respective components.
		activities_employment: {
			no_activities: false,
			activities: [],

			no_employment: false,
			employment: [],
			employment_to_report: '',
			employment_use_previous_file: '',
			employment_resume_file: '',
			employment_resume_file_name: ''
		},

		//Store essay information, component generates HTML automatically
		essay: {
			essay_text: [{ type: 'paragraph', children: [{ text: '' }] }],
			essay_html: '',
			second_essay_text: [{ type: 'paragraph', children: [{ text: '' }] }],
			second_essay_html: '',
			requirement: 'required'
		},

		//Residency information for self & parent
		residency: {
			residency_status: '',
			intend_claim_residency: '',
			provide_parent_info: '',

			parent_provided: false,
			relationship_unable_to_provide: false,
			residency_relationship: '',
			relationship_us_citizen: '',
			relationship_visa_type: '',
			relationship_visa_type_other: '',
			relationship_lived_wi_12_months: '',
			relationship_employed: '',
			relationship_income_taxes: '',
			relationship_registered_vote: '',
			relationship_valid_license: '',

			self_provided: false,
			self_lived_wi_12_months: '',
			self_live_in_wi: '',
			self_lived_wi_date: '',
			self_employed: '',
			self_income_taxes: '',
			self_registered_vote: '',
			self_valid_license: '',
			self_claim_dependent: '',
			self_he_enrolled: ''
		},

		//Submit final section
		submit: {
			coupon_message: '',
			agree_checkbox: '',
			signature: ''
		},

		referrers: []
	},
	getAppTypeText = app => {
		let app_type_code = app.application_modifier.split('_')[0],
			second_degree =
				app.initial_information.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
				app.initial_information.app_type_question_7 === "Bachelor's Degree",
			dictAppTypes = {
				FM: 'Freshman',
				TR: second_degree ? 'Transfer/2nd Degree' : 'Transfer',
				RE: second_degree ? 'Re-Entry/2nd Degree' : 'Re-Entry',
				RET: second_degree ? 'Re-Entry Transfer/2nd Degree' : 'Re-Entry Transfer',
				GR_D_S: 'Graduate Non-Degree',
				GR_I_S: 'Graduate Non-Degree',
				GR: 'Graduate',
				HS: 'High School Non-Degree',
				UG: 'Undergraduate Non-Degree',
				DA: 'Direct Admit'
			};

		return dictAppTypes[app_type_code] || 'Unknown';
	},
	getMajorText = (area_of_study, majors) => {
		let prog_name_1 = GET_LABEL.full(majors, area_of_study, 1),
			prog_name_2 = GET_LABEL.full(majors, area_of_study, 2);

		return prog_name_1 ? (
			<>
				<strong>{prog_name_1}</strong>
				{!!prog_name_2 && (
					<>
						{' '}
						and <strong>{prog_name_2}</strong>
					</>
				)}
			</>
		) : (
			<strong>None</strong>
		);
	};

export const DEFAULT = {
		APP_JSON: default_app_json,
		PROGRAM: {
			id: '-1',
			title: '',
			field_campus_id: '',
			field_academic_level: '',
			field_cmc_program_type: '',
			field_distance_ed_indicator: '',
			field_program_url: '',
			field_selective: '0',
			field_plan_code: '',
			field_subplan_code: '',
			field_program_code: '',
			field_online_plan_code: '',
			field_keywords: [],
			field_career_clusters: [],
			field_application_types: [],
			field_override_campuses: [],
			field_flags: [],
			sub_programs: []
		},
		APP_REC: {
			application_id: '-1',
			date_last_updated: strNow(),
			percentage_complete: 0,
			json_obj: default_app_json
		},
		BYPASS_OBJECT: {
			result: '',
			redeemed: false,
			coupon_code: '',
			coupon_id: '',
			field_application_type: '',
			field_specific_term: null,
			field_campus: {
				id: '',
				title: '',
				field_address_1: null,
				field_address_2: null,
				field_city: null,
				field_state: '',
				field_zip_code: null,
				field_email_address: null,
				field_phone_number: null,
				field_website_url: null,
				field_descriptor: null,
				field_abbreviation: '',
				field_regional_help: null,
				field_parent_campus: null,
				field_cashnet_api_key: '',
				field_cashnet_item_code: ''
			}
		},
		OBJ_PREV_NAMES: {
			first_name: '',
			middle_name: '',
			last_name: ''
		},
		PARENT_INFO: {
			relationship_to_applicant: '',
			parent_phone: '',
			same_current_address: '',
			which_address: '',
			first_name: '',
			last_name: '',
			email_address: '',
			current_address: {
				startValidate: false,
				error: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				other_state: '',
				county: '',
				zip: '',
				country: '',
				other_country: '',
				formatted_address: ''
			}
		},
		HIGH_SCHOOL: {
			selected_highschool: {
				title: '',
				field_city: '',
				field_country: '',
				field_other_country: '',
				field_state: ''
			},
			currently_attend: '',
			hs_attendance_start: '',
			hs_attendance_end: '',
			graduation_date: '',
			did_graduate: '',

			use_previous_file: '',
			high_school_transcript: '',
			high_school_transcript_name: ''
		},
		HS_COURSE: {
			course_title: '',
			credits: ''
		},
		COLLEGE: {
			selected_college: {
				title: '',
				field_city: '',
				field_country: '',
				field_other_country: '',
				field_state: ''
			},
			currently_attend: '',
			college_start: '',
			college_end: '',
			graduation_date: '',
			did_you_earn_degree: '',
			degree_earned: '',
			degree_freetext: '',
			major: '',
			date_error: ''
		},
		COLLEGE_COURSE: {
			course_title: '',
			course_number: '',
			credits: '',
			college_associated: ''
		},
		TEST_SCORE: {
			score_type: '',
			date_taken: '',
			total_score: '',
			future_test: false
		},
		ACTIVITY: {
			activity_category: '',
			activity_name: '',
			participation_grade_level: [],
			level_of_participation: '',
			position_or_leadership_role: '',
			intend_to_participate: ''
		},
		EMPLOYMENT: {
			self_employed: '',
			employer_company: '',
			employer_city: '',
			employer_state: '',
			employer_country: '',
			employer_country_other: '',
			job_title: '',
			current_job: '',
			employment_started: '',
			employment_ended: '',
			hours_per_week: '',
			summer_only: ''
		}
	},
	APP_TYPES = {
		FM_D: 'Freshman Domestic',
		FM_I: 'Freshman International',
		TR_D: 'Transfer Domestic',
		TR_I: 'Transfer International',
		RE_D: 'Re-Entry Domestic',
		RE_I: 'Re-Entry International',
		RET_D: 'Re-Entry Transfer Domestic',
		RET_I: 'Re-Entry Transfer International',
		GR_D: 'Graduate Domestic',
		GR_I: 'Graduate International',
		UG_D_S: 'Undergrad Domestic Special',
		UG_I_S: 'Undergrad International Special',
		HS_D_S: 'High School Special Domestic',
		HS_I_S: 'High School Special International',
		GR_D_S: 'Graduate Special Domestic',
		GR_I_S: 'Graduate Special International',
		DA: 'Direct Admit'
	},
	SUMMARIZE_INIT_INFO = {
		standard: (app, terms, majors, is_submit) => {
			let strAppType = getAppTypeText(app),
				strCampus = app.initial_information.chosen_campus?.title || 'None Selected',
				strTerm = FIND_TERM.by_app(app, terms)?.title,
				strMajors = getMajorText(app.area_of_study, majors),
				multi_program = (app.area_of_study?.selected_primary_2?.id || '-1') !== '-1',
				strTerm_1 = strTerm.split('(')[0]?.trim(),
				strTerm_2 = strTerm.split('(')[1]?.trim(),
				appTypePrefix = app.application_modifier.includes('UG') ? 'an' : 'a';

			return (
				<div className={`app-overview${is_submit ? ' is-submit' : ''}`}>
					<img width={60} height={60} src={_svg.AppSummary} alt='Application Summary' />

					<p>
						You're applying as {appTypePrefix} <strong>{strAppType} student</strong> at{' '}
						<strong>{strCampus}</strong> for the <strong>{strTerm_1} term</strong>
						{!!strTerm_2 && ` (${strTerm_2}`}.
					</p>
					<hr />
					<p>
						You've selected {strMajors} as your major/program{multi_program ? 's' : ''}.
					</p>
				</div>
			);
		},
		direct_admit: (app, majors, campuses, is_submit) => {
			let termYear = app.initial_information.chosen_semester.title.match(/\d+/g)[0],
				plural = Object.keys(app.initial_information.direct_admit).length > 1;

			return (
				<div className={`app-overview${is_submit ? ' is-submit-DA' : ''}`}>
					<p>
						You are expressing interest in {plural ? '' : 'a '}Direct Admit offer{plural ? 's' : ''} as a
						freshman student to explore majors at the following campus{plural ? 'es' : ''} for the{' '}
						<strong>Fall {termYear} term</strong>.
					</p>
					<table>
						<tbody>
							{Object.keys(app.initial_information.direct_admit).map(campus_code => {
								const campus = campuses.find(c => c.field_abbreviation === campus_code),
									strMajors = getMajorText(app.initial_information.direct_admit[campus_code], majors);

								return (
									<tr key={campus_code}>
										<td>{campus.title}</td>
										<td>{strMajors}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			);
		}
	},
	has_fee = _app => {
		if (!_app) return false;

		const { json_obj, application_fee, date_submitted } = _app;

		return (
			!(json_obj.personal_information.waive_fee === 'Yes' && json_obj.personal_information.waive_fee_reason) &&
			!!parseInt(application_fee) &&
			!(!date_submitted && internalTransfer(json_obj))
		);
	},
	formalizeAppType = _app => {
		let app_type = _app.application_modifier.split('_')[0],
			formal_app_type = '';

		switch (app_type) {
			case 'FM':
				formal_app_type = 'Freshman';
				break;
			case 'TR':
				formal_app_type =
					_app.initial_information.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
					_app.initial_information.app_type_question_7 === "Bachelor's Degree"
						? 'Transfer/2nd Degree'
						: 'Transfer';
				break;
			case 'RE':
				formal_app_type = 'Re-Entry';
				break;
			case 'RET':
				formal_app_type = 'Re-Entry Transfer';
				break;
			case 'GR_D_S':
				formal_app_type = 'Graduate Non-Degree';
				break;
			case 'GR_I_S':
				formal_app_type = 'Graduate Non-Degree';
				break;
			case 'GR':
				formal_app_type = 'Graduate';
				break;
			case 'HS':
				formal_app_type = 'High School Non-Degree';
				break;
			case 'UG':
				formal_app_type = 'Undergraduate Non-Degree';
				break;
			case 'DA':
				formal_app_type = 'Direct Admit';
				break;
			default:
				formal_app_type = 'Unknown';
		}

		return formal_app_type;
	},
	calculateProgressbar = _app => {
		const enabled_routes = routes.filter(r => routeEnabledForModifier(r.path, _app) && !!r.completedBoolean),
			completed_routes = enabled_routes.filter(r => _app.screensCompleted[r.completedBoolean]),
			percentage = (completed_routes.length / enabled_routes.length) * 100;

		return Math.round(percentage);
	},
	updateScreensCompleted = (objState, obj) => {
		const { app_id, use_temp_app_rec } = objState;

		if (Object.keys(obj).find(key => !obj[key])) {
			obj.submit = false;
			obj.additional = false;
		}

		Object.keys(obj).forEach(str => {
			const val = obj[str],
				current_route = routes.find(r => r.completedBoolean === str || r.submitBoolean === str);

			if (use_temp_app_rec) {
				objState.temp_app_rec.json_obj.screensCompleted[str] = val;
			} else {
				objState.apps[app_id].json_obj.screensCompleted[str] = val;
			}

			if (current_route?.childOf && current_route.completedBoolean) {
				const parent_route = routes.find(r => r.path === current_route.childOf),
					parent_route_prop = parent_route.completedBoolean,
					current_json = (use_temp_app_rec ? objState.temp_app_rec : objState.apps[app_id]).json_obj,
					incomplete_child_route = routes.find(
						r =>
							r.childOf === current_route.childOf &&
							routeEnabledForModifier(r.path, current_json) &&
							!current_json.screensCompleted[r.completedBoolean]
					);

				if (use_temp_app_rec) {
					objState.temp_app_rec.json_obj.screensCompleted[parent_route_prop] = !incomplete_child_route;
				} else {
					objState.apps[app_id].json_obj.screensCompleted[parent_route_prop] = !incomplete_child_route;
				}
			}
		});

		if (use_temp_app_rec) {
			objState.temp_app_rec.json_obj.percentage_complete = calculateProgressbar(objState.temp_app_rec.json_obj);
		} else {
			objState.apps[app_id].json_obj.percentage_complete = calculateProgressbar(objState.apps[app_id].json_obj);
		}
	},
	calculateReciprocityStatus = (obj, parent_fields_shown) => {
		let {
				term_name,
				term_year,
				dob,
				us_citizen,
				relationship,
				relationship_us_citizen,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license,
				self_lived_wi_12_months,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_wi_hs_grad,
				self_he_enrolled
			} = obj,
			_term_names = {
				Spring: 0,
				Summer: 5,
				Fall: 8
			},
			date_term_start = new Date(term_year, _term_names[term_name], 1),
			age_at_term_start = years_passed(dob, date_term_start),
			check_applicant =
				age_at_term_start >= 18 &&
				us_citizen === 'Yes' &&
				self_lived_wi_12_months === 'Yes' &&
				!self_he_enrolled,
			check_parent =
				parent_fields_shown &&
				relationship !== 'Legal Guardian/Caretaker' &&
				relationship_us_citizen === 'Yes' &&
				relationship_lived_wi_12_months === 'Yes' &&
				self_wi_hs_grad == 'Yes',
			status = 'Needs Review';

		if (check_applicant) {
			const vote_or_license = self_registered_vote === 'Yes' || self_valid_license === 'Yes',
				qualify_on_employment = self_employed === 'Wisconsin' && self_income_taxes === 'Yes',
				qualify_by_govt = self_income_taxes === 'Yes' && vote_or_license,
				qualify_on_hs =
					self_employed === 'Not currently working' &&
					self_income_taxes === 'No, did not file' &&
					vote_or_license &&
					wi_hs_grad;

			if (self_he_enrolled !== 'Yes' && (qualify_on_employment || qualify_by_govt || qualify_on_hs)) {
				status = 'Approved - Self';
			}
		}

		if (check_parent) {
			const vote_or_license = relationship_registered_vote === 'Yes' || relationship_valid_license === 'Yes',
				qualify_on_parent =
					(relationship_employed === 'Wisconsin' &&
						(relationship_income_taxes === 'Yes' ||
							(vote_or_license && relationship_income_taxes === 'No, did not file'))) ||
					(relationship_employed === 'Not currently working' &&
						vote_or_license &&
						['Yes', 'No, did not file'].includes(relationship_income_taxes)) ||
					(vote_or_license && relationship_income_taxes === 'Yes');

			if (qualify_on_parent) status = 'Approved - Parent';
		}

		return status;
	},
	calculateResidencyStatus = (app, for_reciprocity = false) => {
		let { residency: res, initial_information, personal_information } = app,
			international = app.application_modifier.split('_')[1] === 'I',
			date_term_start = getTermStartDate(initial_information.chosen_semester),
			age_at_term_start = years_passed(personal_information.date_of_birth, date_term_start),
			status = 'U',
			wi_hs_grad;

		if (isNaN(age_at_term_start)) age_at_term_start = 0;

		if (res.intend_claim_residency === 'No' || international) return { ...res, residency_status: 'N' };

		if (res.intend_claim_residency !== 'Yes') return { ...res, residency_status: status };

		if (for_reciprocity) {
			wi_hs_grad = res.self_wi_hs_grad === 'Yes';
		} else {
			const collecting_hs_history = ['FM_D', 'FM_I', 'TR_D', 'TR_I', 'HS_D_S', 'UG_D_S', 'UG_I_S', 'DA'].includes(
				app.application_modifier
			);

			wi_hs_grad = collecting_hs_history
				? app.academic_background?.highschools?.some(
						hs => hs.selected_highschool?.field_state === 'WI' && hs.graduation_date !== ''
				  )
				: app.personal_information.self_graduate_wi_highschool === 'Yes';
		}

		const recent_higher_ed = app.academic_background.colleges.some(
				c => c.currently_attend === 'Yes' || (c.college_end.length === 7 && months_passed(c.college_end) < 12)
			),
			check_applicant =
				age_at_term_start >= 18 &&
				res.self_provided &&
				initial_information.us_citizenship_status === 'Yes' &&
				res.self_lived_wi_12_months === 'Yes' &&
				!recent_higher_ed,
			check_parent =
				res.parent_provided &&
				['Mother', 'Father', 'Stepmother', 'Stepfather'].includes(res.residency_relationship) &&
				!res.relationship_unable_to_provide &&
				res.relationship_us_citizen === 'Yes' &&
				res.relationship_lived_wi_12_months === 'Yes' &&
				wi_hs_grad;

		if (check_applicant) {
			const vote_or_license = res.self_registered_vote === 'Yes' || res.self_valid_license === 'Yes',
				qualify_on_employment = res.self_employed === 'Wisconsin' && res.self_income_taxes === 'Yes',
				qualify_by_govt = res.self_income_taxes === 'Yes' && vote_or_license,
				qualify_on_hs =
					res.self_employed === 'Not currently working' &&
					res.self_income_taxes === 'No, did not file' &&
					vote_or_license &&
					wi_hs_grad;

			if (res.self_he_enrolled !== 'Yes' && (qualify_on_employment || qualify_by_govt || qualify_on_hs)) {
				status = 'Y_APPLICANT_CHECK';
			}
		}

		if (check_parent) {
			const vote_or_license =
					res.relationship_registered_vote === 'Yes' || res.relationship_valid_license === 'Yes',
				qualify_on_parent =
					(res.relationship_employed === 'Wisconsin' &&
						(res.relationship_income_taxes === 'Yes' ||
							(vote_or_license && res.relationship_income_taxes === 'No, did not file'))) ||
					(res.relationship_employed === 'Not currently working' &&
						vote_or_license &&
						['Yes', 'No, did not file'].includes(res.relationship_income_taxes)) ||
					(vote_or_license && res.relationship_income_taxes === 'Yes');

			if (qualify_on_parent) status = 'Y_PARENT_CHECK';
		}

		return { ...res, residency_status: status };
	},
	translateOldAppJson = json_obj => {
		let { self_report_scores, report_future_tests, test_scores, act } = json_obj.academic_background,
			{ no_activities, no_employment } = json_obj.activities_employment;

		delete json_obj.is_submitted;
		delete json_obj.submit.application_read_only;

		if (typeof json_obj.initial_information.direct_admit === 'undefined') {
			json_obj.initial_information.direct_admit = {};
		}

		// tms: 10-28-2022: replace array fields that shouldn't be arrays
		if (typeof no_activities !== 'boolean') {
			json_obj.activities_employment.no_activities =
				typeof no_activities != 'undefined' &&
				!no_activities.includes("I don't have activities I wish to report") &&
				!no_activities.includes('I have no activities to report.');
		}

		if (typeof no_employment !== 'boolean') {
			json_obj.activities_employment.no_employment =
				typeof no_employment != 'undefined' && !no_employment.includes('I have no work experience to report.');
		}

		if (Array.isArray(json_obj.submit.agree_checkbox)) {
			json_obj.submit.agree_checkbox = json_obj.submit.agree_checkbox.length ? 'I Agree' : '';
		}

		/* initial_information
		 */
		if (!Object.keys(json_obj.initial_information).includes('reside_in_us')) {
			json_obj.initial_information.reside_in_us = '';
		}

		const semester_fields = Object.keys(json_obj.initial_information.chosen_semester || {});
		if (semester_fields.length < 2) {
			json_obj.initial_information.chosen_semester = { id: '', title: '', global_term: '' };
		} else if (semester_fields.length > 3) {
			const { id, title, global_term } = json_obj.initial_information.chosen_semester;
			json_obj.initial_information.chosen_semester = { id: id, title: title, global_term: global_term };
		}

		/* personal_information
		 */
		if (typeof json_obj.personal_information.working_with_agent === 'undefined') {
			json_obj.personal_information.working_with_agent = '';
			json_obj.personal_information.agent_name = '';

			if (['FM_I', 'TR_I', 'RE_I', 'RET_I'].includes(json_obj.application_modifier)) {
				json_obj.screensCompleted.basicInformationCompleted = false;
			}
		}

		if (typeof json_obj.personal_information.tribal_enrollment_no === 'undefined') {
			json_obj.personal_information.tribal_enrollment_no = '';
		}

		if (typeof json_obj.personal_information.military_service === 'undefined') {
			json_obj.personal_information.military_service = [];
			json_obj.personal_information.veteran_status = [];

			json_obj.screensCompleted.personalInformationAdditionalCompleted = false;
		}

		if (Object.keys(optsTerritory).includes(json_obj.personal_information.birth_country_code)) {
			json_obj.personal_information.birth_state = optsTerritory[json_obj.personal_information.birth_country_code];
			json_obj.personal_information.birth_country_code = 'USA';
		}

		if (Object.keys(optsTerritory).includes(json_obj.personal_information.citizen_country_code)) {
			json_obj.personal_information.citizen_country_code = 'USA';
		}

		if (Object.keys(optsBadState).includes(json_obj.personal_information.birth_state)) {
			json_obj.personal_information.birth_country_code = optsBadState[json_obj.personal_information.birth_state];
			json_obj.personal_information.birth_state = '';
		}

		/*	contact_information
		 */
		if (Object.keys(optsTerritory).includes(json_obj.contact_information.permanent_address?.country)) {
			json_obj.contact_information.permanent_address.state =
				optsTerritory[json_obj.contact_information.permanent_address.country];
			json_obj.contact_information.permanent_address.country = 'USA';
		}

		if (Object.keys(optsTerritory).includes(json_obj.contact_information.mailing_address?.country)) {
			json_obj.contact_information.mailing_address.state =
				optsTerritory[json_obj.contact_information.mailing_address.country];
			json_obj.contact_information.mailing_address.country = 'USA';
		}

		if (Object.keys(optsBadState).includes(json_obj.contact_information.permanent_address?.state)) {
			json_obj.contact_information.permanent_address.country =
				optsBadState[json_obj.contact_information.permanent_address.state];
			json_obj.contact_information.permanent_address.state = '';
		}

		if (Object.keys(optsBadState).includes(json_obj.contact_information.mailing_address?.state)) {
			json_obj.contact_information.mailing_address.country =
				optsBadState[json_obj.contact_information.mailing_address.state];
			json_obj.contact_information.mailing_address.state = '';
		}

		/*	parent_info
		 */
		(json_obj.parent_info.parents || []).forEach(parent => {
			if (Object.keys(optsTerritory).includes(parent.current_address?.country)) {
				parent.current_address.state = optsTerritory[parent.current_address.country];
				parent.current_address.country = 'USA';
			}

			if (Object.keys(optsBadState).includes(parent.current_address?.state)) {
				parent.current_address.country = optsBadState[parent.current_address.state];
				parent.current_address.state = '';
			}
		});

		/* academic_background
		 */
		if ((_.isEmpty(self_report_scores) || _.isEmpty(report_future_tests)) && !_.isEmpty(act)) {
			const has_scores = !!_.findWhere(test_scores || [], { future_test: false }),
				has_scheduled_tests = !!_.findWhere(test_scores || [], { future_test: true });

			if (act === 'Report future test date') {
				json_obj.academic_background.self_report_scores = has_scores ? 'Yes' : 'No';
				json_obj.academic_background.report_future_tests = 'Yes';
			} else {
				json_obj.academic_background.self_report_scores = act;
				json_obj.academic_background.report_future_tests = has_scheduled_tests ? 'Yes' : 'No';
			}

			delete json_obj.academic_background.act;
			delete json_obj.academic_background.test_scores_page_completed;
		}

		//	from when college transcripts were still a 1-to-1 relationship with colleges
		const has_old_college_transcript_structure = !!(json_obj.academic_background.colleges || []).find(
			college => typeof college.college_transcript !== 'undefined'
		);

		if (has_old_college_transcript_structure) {
			const colleges_with_old_transcripts = json_obj.academic_background.colleges.filter(
				college => college.currently_attend === 'Yes' && !!college.college_transcript
			);

			if (colleges_with_old_transcripts.length === 1) {
				const c = colleges_with_old_transcripts[0];

				json_obj.academic_background.college_show_courses_option = 'Upload file';
				json_obj.academic_background.college_course_use_previous_file = c.use_previous_file;
				json_obj.academic_background.college_course_schedule_file = c.college_transcript;
				json_obj.academic_background.college_course_schedule_file_name = c.college_transcript_name;

				json_obj.academic_background.colleges.forEach(college => {
					if (college.selected_college.title === c.selected_college.title) {
						['use_previous_file', 'upload_error', 'college_transcript', 'college_transcript_name'].forEach(
							prop => {
								delete college[prop];
							}
						);
					}
				});
			}

			json_obj.screensCompleted.academicBackgroundCollegeCoursesCompleted = false; // since we've changed the app, just make the applicant look at it again
		}

		// from when we were still collecting transcripts from HS graduates
		(json_obj.academic_background.highschools || []).forEach(school => {
			if (school.currently_attend !== 'Yes' && !!school.high_school_transcript) {
				['high_school_transcript', 'high_school_transcript_name', 'upload_error', 'use_previous_file'].forEach(
					key => {
						school[key] = '';
					}
				);
			}

			if (Object.keys(optsTerritory).includes(school.field_country)) {
				school.field_state = optsTerritory[school.field_country];
				school.field_country = 'USA';
			}

			if (Object.keys(optsBadState).includes(school.field_state)) {
				school.field_country = optsBadState[school.field_state];
				school.field_state = '';
			}
		});

		(json_obj.academic_background.colleges || []).forEach(school => {
			if (Object.keys(optsTerritory).includes(school.field_country)) {
				school.field_state = optsTerritory[school.field_country];
				school.field_country = 'USA';
			}

			if (Object.keys(optsBadState).includes(school.field_state)) {
				school.field_country = optsBadState[school.field_state];
				school.field_state = '';
			}
		});

		// from when needs based waiver lived in the submit process
		let old_needs_based_waiver = false;
		['waive_fee', 'waive_fee_reason', 'waive_fee_accepted_warning'].forEach(prop => {
			if (typeof json_obj.submit[prop] !== 'undefined') {
				json_obj.personal_information[prop] = json_obj.submit[prop];
				old_needs_based_waiver = true;
			}

			delete json_obj.submit[prop];
		});

		if (['FM_D', 'TR_D'].includes(json_obj.application_modifier)) {
			if (old_needs_based_waiver && json_obj.screensCompleted.personalInformationAdditionalCompleted) {
				json_obj.screensCompleted.personalInformationAdditionalCompleted =
					json_obj.personal_information.waive_fee === 'No' ||
					(json_obj.personal_information.waive_fee_reason &&
						json_obj.personal_information.waive_fee_accepted_warning);

				if (!json_obj.screensCompleted.personalInformationAdditionalCompleted) {
					json_obj.screensCompleted.personalInformationCompleted = false;
				}
			}
		}

		/* activities_employment
		 */
		// conversion for language update February 2022. Can be removed after Sept 2022
		if (json_obj.personal_information.ssn_tin === 'Social Security Number') {
			json_obj.personal_information.ssn_tin = 'US Social Security Number';
		}

		if (json_obj.personal_information.ssn_tin === 'Tax Identification Number') {
			json_obj.personal_information.ssn_tin = 'US Tax Identification Number';
		}

		// from before we treated TR as the same as FR on this page
		if (json_obj.application_modifier.includes('TR')) {
			const { employment, employment_resume_file, no_employment } = json_obj.activities_employment;

			json_obj.screensCompleted.employmentCompleted =
				!!employment.length || !!employment_resume_file || !!no_employment;
		}

		(json_obj.activities_employment.employment || []).forEach(employer => {
			if (Object.keys(optsTerritory).includes(employer.employer_country)) {
				employer.employer_state = optsTerritory[employer.employer_country];
				employer.employer_country = 'USA';
			}

			if (Object.keys(optsBadState).includes(employer.employer_state)) {
				employer.employer_country = optsBadState[employer.employer_state];
				employer.employer_state = '';
			}
		});

		// new fields added for 2022 eApp governance requests
		if (typeof json_obj.residency.self_live_in_wi === 'undefined') {
			json_obj.residency.self_live_in_wi = '';
			json_obj.residency.self_lived_wi_date = '';
			json_obj.residency.self_claim_dependent = '';
			json_obj.residency.relationship_visa_type = '';
			json_obj.residency.relationship_visa_type_other = '';

			if (json_obj.residency.intend_claim_residency === 'Yes') {
				json_obj.residency.residency_status = 'U';
				json_obj.screensCompleted.residencyCompleted = false;
			}
		}

		if (typeof json_obj.residency.self_he_enrolled === 'undefined') json_obj.residency.self_he_enrolled = '';

		// Revise ACT question options
		const selected_removed_opt =
				json_obj.academic_background.act_transition_option ===
				'Option 3:  I am not sure what I am going to do yet when it comes to submitting an ACT and/or SAT score.',
			no_longer_asked =
				json_obj.application_modifier === 'TR_D' && !!json_obj.academic_background.act_transition_option;

		if (selected_removed_opt || no_longer_asked) {
			json_obj.academic_background.act_transition_option = '';
			json_obj.screensCompleted.academicBackgroundTestScoresCompleted = false;
		}

		// tms:  8-1-2022, fixing some data saved in the wrong place
		if (typeof json_obj.academicBackgroundTestScoresCompleted !== 'undefined') {
			delete json_obj.academicBackgroundTestScoresCompleted;
			json_obj.screensCompleted.academicBackgroundTestScoresCompleted = false;
		}

		if (
			internalTransfer(json_obj) &&
			['LAC', 'MSN'].includes(json_obj.initial_information.chosen_campus?.field_abbreviation) &&
			json_obj.essay.second_essay_html[0]?.length > '<p></p>'.length
		) {
			json_obj.essay.essay_html = json_obj.essay.second_essay_html;
			json_obj.essay.essay_text = json_obj.essay.second_essay_text;
			json_obj.essay.second_essay_html = cloneDeep(default_app_json.essay.second_essay_html);
			json_obj.essay.second_essay_text = cloneDeep(default_app_json.essay.second_essay_text);
		}

		if (typeof json_obj.essay.requirement === 'undefined') {
			json_obj.essay.requirement = 'required';
		}

		// tms: 8-4-2022: fix for bug saving empty colleges
		const iEmptyCollege = json_obj.academic_background.colleges.findIndex(c => !c.selected_college?.title);
		if (iEmptyCollege > -1) {
			json_obj.academic_background.colleges.splice(iEmptyCollege, 1);
			if (!json_obj.academic_background.colleges.length) {
				json_obj.screensCompleted.academicBackgroundCollegeCompleted = false;
			}
		}

		// tms: 8-29-2023: fix for bug where undecided is the 2nd program
		const { selected_primary_1, selected_primary_2 } = json_obj.area_of_study;
		if (selected_primary_1?.title === 'Undecided' && !!selected_primary_2?.title) {
			const cloned_prog = cloneDeep(selected_primary_2);
			json_obj.area_of_study.selected_primary_2 = cloneDeep(selected_primary_1);
			json_obj.area_of_study.selected_primary_1 = cloned_prog;
		}

		if (typeof json_obj.referrers === 'undefined') json_obj.referrers = [];

		/* tms, remove properties that have been migrated elsewhere (likely to local state properties)
		 */
		delete json_obj.summary;
		delete json_obj.initial_information.select_campus_page_completed;
		delete json_obj.initial_information.chosen_campusError;
		delete json_obj.contact_information.page_completed;
		delete json_obj.contact_information.permanent_address.startValidate;
		delete json_obj.contact_information.mailing_address.startValidate;
		delete json_obj.area_of_study.page_completed;
		delete json_obj.area_of_study.search_head;
		delete json_obj.area_of_study.search_child_id;
		delete json_obj.area_of_study.search_error;
		delete json_obj.area_of_study.search_selected_value;
		delete json_obj.residency.page_completed;
	},
	cloneApp = (json_obj, existing_json, multi_campus, essay_requirement = 'required') => {
		[
			'personal_information',
			'parent_info',
			'contact_information',
			'academic_background',
			'activities_employment',
			'residency',
			'submit'
		].forEach(prop => {
			json_obj[prop] = cloneDeep(existing_json[prop]);
		});

		if (!routeEnabledForModifier('/personal-information-additional', json_obj)) {
			json_obj.personal_information = {
				...json_obj.personal_information,
				military_service: [],
				veteran_status: [],
				family_earned_degree: '',
				self_graduate_wi_highschool: '',
				heritage_hispanic: '',
				selected_ethnicities: [],
				heritage_race: [],
				heritage_tribe_code: '',
				other_tribe: '',
				tribal_enrollment_no: ''
			};
		}

		if (!['FM_D', 'TR_D'].includes(json_obj.application_modifier)) {
			json_obj.personal_information.waive_fee = '';
			json_obj.personal_information.waive_fee_reason = '';
			json_obj.personal_information.waive_fee_accepted_warning = '';
		}

		if (!routeEnabledForModifier('/parent-information', json_obj)) {
			json_obj.parent_info = cloneDeep(default_app_json.parent_info);
		}

		if (!routeEnabledForModifier('/academic-background-hs', json_obj)) {
			json_obj.academic_background.highschools = [];
		}

		if (!routeEnabledForModifier('/academic-background-hs-courses', json_obj)) {
			json_obj.academic_background.hs_course_use_previous_file = '';
			json_obj.academic_background.hs_course_schedule_file = '';
			json_obj.academic_background.hs_course_schedule_file_name = '';
			json_obj.academic_background.any_dual_enrollment = '';
			json_obj.academic_background.hs_show_courses_option = '';
			json_obj.academic_background.courses = [];
		}

		if (!routeEnabledForModifier('/academic-background-higher-education', json_obj)) {
			json_obj.academic_background.colleges = [];
		}

		if (!routeEnabledForModifier('/academic-background-higher-education-courses', json_obj)) {
			json_obj.academic_background = {
				...json_obj.academic_background,
				college_course_use_previous_file: '',
				college_course_schedule_file: '',
				college_course_schedule_file_name: '',
				college_courses_in_progress: [],
				college_show_courses_option: ''
			};
		}

		if (!routeEnabledForModifier('/academic-background-test-scores', json_obj)) {
			json_obj.academic_background = {
				...json_obj.academic_background,
				act: '',
				act_transition_option: '',
				test_scores: []
			};
		}

		if (routeEnabledForModifier('/residency', json_obj)) {
			if (!multi_campus) {
				// residency is enabled - force student to re-check options
				json_obj.residency.parent_provided = false;
				json_obj.residency.self_provided = false;

				if (json_obj.residency.residency_status.includes('Y_')) json_obj.residency.residency_status = 'U';
			}
		} else {
			json_obj.residency = cloneDeep(default_app_json.residency);
		}

		if (!routeEnabledForModifier('/activities', json_obj)) {
			json_obj.activities_employment = {
				...json_obj.activities_employment,
				activities: [],
				employment_to_report: '',
				employment: [],
				employment_use_previous_file: '',
				employment_resume_file: '',
				employment_resume_file_name: ''
			};
		}

		json_obj.essay.requirement = essay_requirement;

		translateOldAppJson(json_obj);
		json_obj.submit = cloneDeep(default_app_json.submit); // submit is only needed for translateOldAppJson
	},
	internalTransfer = _app => {
		const { application_modifier, academic_background } = _app;

		if (application_modifier.includes('TR_')) {
			let end_dates = (academic_background.colleges || [])
				.filter(c => !["Master's Degree", 'Doctorate'].includes(c.degree_earned))
				.map(c => c.college_end)
				.filter(end_month => months_passed(end_month) <= 12);

			if (!end_dates.length) return false;

			end_dates.sort((ed1, ed2) => {
				const y1 = Number(ed1.split('/')[1]),
					y2 = Number(ed2.split('/')[1]),
					m1 = Number(ed1.split('/')[0]),
					m2 = Number(ed2.split('/')[0]);

				if (y1 > y2) {
					return -1;
				} else if (y2 > y1) {
					return 1;
				} else if (m1 > m2) {
					return -1;
				} else if (m2 > m1) {
					return 1;
				}

				return 0;
			});

			return academic_background.colleges
				.filter(c => c.college_end === end_dates[0])
				.every(c => c.selected_college.field_is_uw_system_campus === '1');
		}

		return false;
	},
	resolveModifier = init_info => {
		let objReturn = { modifier: '' },
			internationalModifier =
				init_info.us_citizenship_status === 'Yes' ||
				['Refugee', 'Resident Alien', 'Applying Alien'].includes(init_info.alien_status) ||
				(init_info.alien_status === 'Other' && init_info.reside_in_us === 'Yes') ||
				init_info.need_f1_j1 === 'No'
					? 'D'
					: 'I';

		//DEGREE
		if (init_info.studentPlan === 'Degree') {
			//Start with further logic first
			if (
				init_info.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
				init_info.app_type_question_7 === 'Graduate Degree'
			) {
				objReturn = {
					modifier: `GR_${internationalModifier}`,
					page: 9
				};
			} else if (
				init_info.app_type_question_5 === "I've earned a bachelor's degree or higher" &&
				init_info.app_type_question_7 === "Bachelor's Degree"
			) {
				const prefix = init_info.app_type_question_6 === 'Yes' ? 'RE' : 'TR';

				objReturn = {
					modifier: `${prefix}_${internationalModifier}`,
					page: 9
				};
			} else if (
				init_info.app_type_question_5 === "I've attended some college" &&
				init_info.app_type_question_6 === 'Yes'
			) {
				objReturn = {
					modifier: `RE_${internationalModifier}`,
					page: 8
				};
			} else if (
				init_info.app_type_question_5 === "I've attended some college" &&
				init_info.app_type_question_6 === 'No'
			) {
				objReturn = {
					modifier: `TR_${internationalModifier}`,
					page: 8
				};
			} else if (
				init_info.app_type_question_4 === 'Yes' &&
				init_info.app_type_question_5 === "I've not attended college or I took college courses in high school"
			) {
				objReturn = {
					modifier: `FM_${internationalModifier}`,
					page: 7
				};
			} else if (init_info.app_type_question_4 === 'No') {
				objReturn = {
					modifier: `FM_${internationalModifier}`,
					page: 6
				};
			}
		} else if (init_info.studentPlan === 'Few Courses') {
			if (init_info.app_type_question_4 === 'No') {
				objReturn = {
					modifier: `HS_${internationalModifier}_S`,
					page: 6
				};
			} else if (init_info.app_type_question_5 === 'Undergraduate') {
				objReturn = {
					modifier: `UG_${internationalModifier}_S`,
					page: 7
				};
			} else if (init_info.app_type_question_6 === 'Yes') {
				objReturn = {
					modifier: `GR_${internationalModifier}_S`,
					page: 8
				};
			}
		}

		if (init_info.alien_status === 'Intl Online') {
			objReturn.modifier = objReturn.modifier.includes('GR')
				? objReturn.modifier.replace('_I', '_D')
				: objReturn.modifier.replace('_D', '_I');
		}

		return objReturn;
	},
	resolveReEntry = init_info => {
		let mod = resolveModifier(init_info).modifier;

		if (mod) {
			const res_letter = mod.endsWith('D') ? 'D' : 'I';

			if (init_info.re_entry_attended_campus === 'Yes') {
				if (init_info.re_entry_attended_other === 'Yes') {
					return `RET_${res_letter}`;
				} else if (init_info.re_entry_attended_other === 'No') {
					return `RE_${res_letter}`;
				}
			}
		}

		return '';
	},
	routeEnabledForModifier = (_path, _app) => {
		const route = routes.find(r => r.path === _path);

		if (!route.enabledModifiers) return true;

		if (route.enabledModifiers.some(str => _app.application_modifier === str)) {
			if (_path === '/academic-background-hs-courses') {
				return _app.academic_background.highschools.some(school => school.currently_attend === 'Yes');
			} else if (_path === '/academic-background-higher-education-courses') {
				return (
					show_higher_ed(_app) &&
					_app.academic_background.colleges.some(c => months_passed(c.college_end) < 0)
				);
			} else if (_path === '/academic-background-higher-education') {
				return show_higher_ed(_app);
			} else if (_path === '/essay') {
				if (_app.essay.requirement === 'none') return false;

				if (_app.application_modifier.includes('TR_')) {
					const { field_abbreviation: code } = _app.initial_information.chosen_campus,
						always_essay = ['LAC', 'MSN'];

					return always_essay.includes(code) || !internalTransfer(_app);
				}
			} else if (_path === '/additional-applications') {
				return !IS.flex(_app.area_of_study.selected_primary_1, false);
			} else if (_path === '/needs-based-waiver') {
				return !_app.date_submitted;
			}

			return true;
		}

		return false;
	},
	show_higher_ed = _app => {
		let higherEducationCheckBypassed = [
			'TR_D',
			'TR_I',
			'RE_D',
			'RE_I',
			'RET_D',
			'RET_I',
			'GR_D',
			'GR_I',
			'GR_D_S',
			'GR_I_S'
		];

		if (higherEducationCheckBypassed.includes(_app.application_modifier)) {
			return true; //HE always shown for bypassed modifiers
		} else {
			return _app.academic_background.any_dual_enrollment === 'Yes'; //Make sure they are shown college block routes
		}
	},
	check_missing_data = json_obj => {
		// tms:  check for missing data here, setting the screensCompleted value(s) to false if there is any

		let val = false;

		if (json_obj.application_modifier !== 'DA' && !json_obj.area_of_study.selected_primary_1.title) {
			json_obj.screensCompleted.selectAreaStudyCompleted = false;
			val = true;
		}

		return val;
	};
