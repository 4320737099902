import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl } from 'react-bootstrap';

let month_focused = false,
	date_focused = false,
	year_focused = false,
	optsMonth = {
		January: 31,
		February: 29,
		March: 31,
		April: 30,
		May: 31,
		June: 30,
		July: 31,
		August: 31,
		September: 30,
		October: 31,
		November: 30,
		December: 31
	},
	getDays = m => {
		const j = Number(m),
			keyMonth = Object.keys(optsMonth)[j - 1];

		return optsMonth[keyMonth];
	};

export default class DateInput extends Component {
	constructor(props) {
		super(props);

		const { value, fullDate } = props,
			valid_date = value?.length === fullDate ? 10 : 7;

		this.state = {
			hide_invalid: !value,
			month: valid_date ? value.substring(0, 2) : '',
			date: valid_date && fullDate ? value.substring(3, 5) : '',
			year: ''
		};

		if (valid_date) this.state.year = fullDate ? value.substring(6) : value.substring(3);
	}

	componentDidUpdate(prevProps, prevState) {
		const { onChange, fullDate } = this.props,
			{ month, date, year } = this.state;

		if (fullDate) {
			if (month !== prevState.month || date !== prevState.date || year !== prevState.year) {
				const valid_date = month && date && year?.length === 4;
				onChange(valid_date ? `${month}/${date}/${year}` : '');
			}
		} else if (month !== prevState.month || year !== prevState.year) {
			const valid_date = month && year?.length === 4;
			onChange(valid_date ? `${month}/${year}` : '');
		}
	}

	onBlurFields = () => {
		const { fullDate } = this.props;
		if (month_focused && (!fullDate || date_focused) && year_focused) this.setState({ hide_invalid: false });
	};

	render() {
		let { disabled, value, label, required, isInvalid, cols, hide_optional, fullDate } = this.props,
			{ hide_invalid, month, date, year } = this.state,
			highlight_invalid =
				!hide_invalid && (isInvalid || (required && !value) || (!!value && !value.match(this.pattern)));

		return (
			<div
				className={`col-md-${cols}${highlight_invalid ? ' invalid' : ''} date-picker ${
					fullDate ? 'full-date' : ''
				}`}>
				<FormGroup>
					{label && (
						<label>
							{label}
							{!(required || hide_optional) && <small>(Optional)</small>}
						</label>
					)}

					<FormControl
						disabled={disabled}
						componentClass='select'
						value={month}
						onChange={e => {
							const { value } = e.target;

							if (value) {
								const strMonth = Object.keys(optsMonth)[Number(value - 1)],
									num_days = optsMonth[strMonth];

								if (Number(date) > num_days) this.setState({ date: '' });
							} else {
								this.setState({ date: '' });
							}

							this.setState({ month: value });
						}}
						onBlur={this.onBlurFields}
						onFocus={() => {
							month_focused = true;
						}}>
						<option value=''>Select Month</option>
						{Object.keys(optsMonth).map((key, i) => {
							const val = i + 1 < 10 ? `0${i + 1}` : i + 1;

							return (
								<option key={val} value={val}>
									{key}
								</option>
							);
						})}
					</FormControl>
					{!!fullDate && (
						<FormControl
							disabled={disabled}
							componentClass='select'
							value={date}
							onChange={e => this.setState({ date: e.target.value })}
							onBlur={this.onBlurFields}
							onFocus={() => {
								date_focused = true;
							}}>
							<option value=''>Day</option>
							{month
								? [...Array(31)]
										.filter((x, i) => i + 1 <= getDays(month))
										.map((x, i) => {
											const str = i + 1 < 10 ? `0${i + 1}` : i + 1;

											return (
												<option key={i} value={str}>
													{i + 1}
												</option>
											);
										})
								: null}
						</FormControl>
					)}

					<input
						disabled={disabled}
						className='form-control'
						placeholder='YYYY'
						type='text'
						value={year}
						maxLength={4}
						onChange={e => this.setState({ year: e.target.value.replace(/[^0-9.]/g, '') })}
						onBlur={this.onBlurFields}
						onFocus={() => {
							year_focused = true;
						}}
					/>
				</FormGroup>
			</div>
		);
	}
}

DateInput.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	fullDate: PropTypes.bool,
	required: PropTypes.bool,
	isInvalid: PropTypes.bool,
	cols: PropTypes.number.isRequired,
	hide_optional: PropTypes.bool
};
