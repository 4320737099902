import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import 'react-slidedown/lib/slidedown.css';

import routes from 'components/routes';
import SidebarListItem from './SidebarListItem';
import SidebarDropdown from './SidebarDropdown';
import ProgressBarFull from 'components/ProgressBar';
import withRouter from 'components/Wrappers/withRouter';
import { routeEnabledForModifier, calculateProgressbar } from 'components/modules/app';
import { getIncompleteRoutes } from 'components/modules/nav';
import _svg from 'components/modules/svg';
import ContactUsModal from 'views/Public/Modals/ContactUs';

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.later = !!props.location.state?.later;
	}

	activeRoute(routePath) {
		return this.props.location.pathname.indexOf(routePath) > -1 ? 'active' : '';
	}

	openContactUsModal = e => {
		this.setState({ showModal: true });
	};

	closeModal = e => {
		this.setState({ showModal: false });
	};

	completedScreen(routePath) {
		const { screensCompleted } = this.props.app;

		let returnValue = false;

		routes.forEach(prop => {
			if (prop.path === routePath) {
				if (prop.completedBoolean) {
					returnValue = screensCompleted[prop.completedBoolean];
				} else if (prop.submitBoolean) {
					returnValue = screensCompleted[prop.submitBoolean];
				}
			}
		});

		return returnValue;
	}

	getPreviousRoute(currentRoutePath) {
		const { app } = this.props;

		let lastRoute = '',
			found = false;

		routes.forEach(route => {
			if (routeEnabledForModifier(route.path, app) || !found) {
				if (route.path === currentRoutePath) {
					found = true;
				} else {
					lastRoute = route;
				}
			}
		});

		return lastRoute;
	}

	getAllChildRoutes(routePath) {
		const { app } = this.props,
			submit_paths = routes
				.filter(r => !!r.submitBoolean)
				.map(r => ({ path: r.path, submitBoolean: r.submitBoolean }));

		return routes
			.filter(r => !!r.childOf && r.childOf === routePath && routeEnabledForModifier(r.path, app))
			.map(r => {
				let blocked = routePath === '/submit' && !!r.submitBoolean && !!getIncompleteRoutes(app).length,
					i = submit_paths.findIndex(_r => _r.path === r.path);

				// tms:  submit pages can't rely on getIncompleteRoutes; just look at completed value of previous page
				if (!blocked && i > 0) blocked = !app.screensCompleted[submit_paths[i - 1].submitBoolean];

				return (
					<SidebarListItem
						key={r.path}
						name={r.name}
						routePath={r.path}
						disabledRoute={blocked}
						extraClass={this.activeRoute(r.path)}
						completed={this.completedScreen(r.path)}
						type='standalone'
						hideSpacer={true}
					/>
				);
			});
	}

	getCurrentRouteSidebar() {
		const { app, app_id, email, location } = this.props,
			this_route = routes.find(x => x.path === location.pathname);

		let returnRoutes = [];

		if (this_route && (this_route.loggedInRoutes || this_route.preAuthRoutes)) {
			let loggedInRoutes = [];
			if (this_route.loggedInRoutes) loggedInRoutes = this_route.loggedInRoutes;

			let preAuthRoutes = [];
			if (this_route.preAuthRoutes) preAuthRoutes = this_route.preAuthRoutes;

			routes.forEach(route => {
				let useRoutes = email || !preAuthRoutes.length ? loggedInRoutes : preAuthRoutes;

				if ((email && route.path === '/sign-out') || (app_id && route.path === '/save-and-return-later')) {
					returnRoutes.push(route);
				} else if (useRoutes.includes(route.path) && routeEnabledForModifier(route.path, app)) {
					if (route.childOf) {
						return;
					} else {
						returnRoutes.push(route);
					}
				}
			});
		}

		return returnRoutes;
	}

	render() {
		const { app, app_id, support_student_id, location, force_pwd_reset } = this.props,
			{ showModal } = this.state,
			hideOnPaymentPage = location.pathname === '/payment' && this.later,
			percentage = app_id ? calculateProgressbar(app) : 0,
			homepageSidebar = [
				'/',
				'/direct-admit',
				'/sign-in',
				'/create-account',
				'/reset-password',
				'/reset-password-form',
				'/link-direct-admit'
			].includes(location.pathname),
			{ REACT_APP_ENV } = process.env;

		if (force_pwd_reset || hideOnPaymentPage) return null;

		let headerText = '';
		if (support_student_id) headerText += 'Support Mode';
		if (app.date_submitted) headerText += ' Read Only';

		if (REACT_APP_ENV !== 'production') {
			headerText = `${headerText} ${REACT_APP_ENV === 'practice' ? 'PRACTICE' : 'DEVELOPMENT'}`;
		}

		let this_route = routes.find(x => x.path === location.pathname);

		if (homepageSidebar) {
			return (
				<div id='sidebar' className='sidebar homepageSidebar'>
					<div className='sidebar-wrapper'>
						<div className='sidebarHeader'>
							<img className='sidebarLogo' src={_svg.UWS_white} alt='UWs Logo' />
							{!!headerText && <h4 className='white italic'>{headerText}</h4>}
						</div>

						<ul className='nav'>
							{this.getCurrentRouteSidebar().map(prop => {
								if (prop.name === 'Direct Admit') {
									return (
										<SidebarDropdown
											routePath={prop.path}
											name='Direct Admit'
											submenuOpen={true}
											imgLogo={_svg.MyAccount}
											extraClass={this.activeRoute(prop.path)}
											hideSpacer={true}
											key={prop.path}>
											<ul className='ulnav'>
												<SidebarListItem
													key={`${prop.path}-docs`}
													img={_svg.AccountInformation}
													name='More Information'
													routePath='https://www.wisconsin.edu/direct-admissions/'
													hideSpacer={true}
												/>
											</ul>
										</SidebarDropdown>
									);
								}

								return (
									<SidebarListItem
										key={prop.path}
										name={prop.name}
										routePath={prop.path}
										completed={this.completedScreen(prop.path)}
										extraClass={this.activeRoute(prop.path)}
										type='standalone'
										hideSpacer={true}
									/>
								);
							})}
						</ul>
					</div>
				</div>
			);
		} else {
			let { title: app_title } = app?.initial_information?.chosen_campus || {};
			if (app.application_modifier === 'DA') app_title = 'Direct Admit Wisconsin';

			return (
				<div id='sidebar' className='sidebar'>
					<Modal show={showModal}>
						<ContactUsModal closeModal={this.closeModal} />
					</Modal>

					<div className='sidebar-wrapper'>
						<div className='sidebarHeader'>
							<img className='sidebarLogo' src={_svg.UWS_white} alt='UWs Logo' />
							{!!headerText && <h4 className='white italic'>{headerText}</h4>}
						</div>

						{this_route && 'enabledModifiers' in this_route && app_title && <h3>{app_title}</h3>}

						<ul className='nav'>
							{this.getCurrentRouteSidebar().map((prop, index, key) => {
								//SKIP ANY ON RAMP IF READ ONLY
								let skipOnRamp = [
									'/select-modifier',
									'/select-campus',
									'/course-of-study',
									'/select-semester'
								];

								if (skipOnRamp.includes(prop.path) && !!app.date_submitted) {
									return null;
								}

								//DROPDOWN ROUTES
								if (prop.parentDropdown) {
									let navBorder = false;

									//Get all child sidebar list item
									let childrenElements = (
										<ul className='ulnav'>{this.getAllChildRoutes(prop.path)}</ul>
									);

									return (
										<div key={prop.path}>
											<SidebarDropdown
												routePath={prop.path}
												name={prop.name}
												submenuOpen={app.submenus[prop.submenuName]}
												submenuName={prop.submenuName}
												extraClass={this.activeRoute(prop.path)}
												completed={this.completedScreen(prop.path)}
												navBorder={navBorder}
												type='standalone'
												key={prop.path}>
												{childrenElements}
											</SidebarDropdown>
										</div>
									);
								} else if (prop.name === 'My Account') {
									return (
										<SidebarDropdown
											routePath={prop.path}
											name='My Account'
											submenuOpen={location.pathname === '/my-account'}
											imgLogo={_svg.MyAccount}
											submenuName='my_account_open'
											extraClass={this.activeRoute(prop.path)}
											hideSpacer={true}
											key={prop.path}>
											<ul className='ulnav'>
												<SidebarListItem
													key={`${prop.path}-info`}
													img={_svg.AccountInformation}
													name='Account Information'
													routePath='/account'
													extraClass={this.activeRoute('/account')}
													hideSpacer={true}
												/>
												<SidebarListItem
													key={`${prop.path}-docs`}
													img={_svg.MyDocuments}
													name='My Documents'
													routePath='/documents'
													extraClass={this.activeRoute('/documents')}
													hideSpacer={true}
												/>
												<SidebarListItem
													key='reciprocity'
													img={_svg.Reciprocity}
													name='Minnesota Reciprocity'
													routePath='/reciprocity'
													extraClass={this.activeRoute('/reciprocity')}
													hideSpacer={true}
												/>
											</ul>
										</SidebarDropdown>
									);
								} else if (prop.name === 'Direct Admit') {
									return (
										<SidebarDropdown
											routePath={prop.path}
											name='Direct Admit'
											submenuOpen={true}
											imgLogo={_svg.MyAccount}
											extraClass={this.activeRoute(prop.path)}
											hideSpacer={true}
											key={prop.path}>
											<ul className='ulnav'>
												<SidebarListItem
													key={`${prop.path}-docs`}
													img={_svg.AccountInformation}
													name='More Information'
													routePath='https://www.wisconsin.edu/direct-admissions/'
													hideSpacer={true}
												/>
											</ul>
										</SidebarDropdown>
									);
								} else if (prop.name === 'Contact Us') {
									return (
										<li onClick={this.openContactUsModal} key={`sakey ${prop.name}`}>
											<div className='nav-link'>
												<img
													alt='Help nav link'
													className='sidebarIcon'
													height='15px'
													width='15px'
													src={_svg.ContactUs}
												/>
												<p className='sidebarRouteName'>{prop.name}</p>
											</div>
										</li>
									);
								} else {
									//DEFAULT

									let route_name = `${prop.name}`;
									if (route_name === 'Direct Admit - Select Campus') route_name = 'Select Campuses';

									return (
										<SidebarListItem
											key={prop.path}
											name={route_name}
											routePath={prop.path}
											completed={this.completedScreen(prop.path)}
											extraClass={this.activeRoute(prop.path)}
											type='standalone'
										/>
									);
								}
							})}
						</ul>
					</div>

					{this_route && this_route.showProgressBar && <ProgressBarFull percentage={percentage} />}
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	const { app_id, apps, support_student_id, temp_app_rec, email, force_pwd_reset } = state.user;

	return {
		support_student_id: support_student_id,
		app_id: app_id,
		app: (app_id && apps[app_id] ? apps[app_id] : temp_app_rec).json_obj,
		email: email,
		force_pwd_reset: force_pwd_reset
	};
};

export default withRouter(connect(mapStateToProps)(Sidebar));
