import * as Sentry from '@sentry/react';
import _ from 'underscore';

const { REACT_APP_ENV } = process.env,
	not_local = ['staging', 'practice', 'production'].includes(REACT_APP_ENV);

export const FILE_UPLOAD = {
		min_file_size: 1000,
		err_msg: {
			title: 'Error Uploading File',
			message:
				"The file you've uploaded does not meet our minimum file size.  Please check that the file has been properly saved and try again.",
			buttons: [{ label: 'OK' }]
		}
	},
	REGEXES = {
		email: new RegExp(
			/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
		),
		dob: '^(0[1-9]|1[0-2])/([0-2][0-9]|(3)[0-1])/(19|20)\\d{2}$'
	},
	SENTRY = {
		use: not_local,
		set_tag: (prop, val) => {
			if (!val) val = '';

			if (not_local) Sentry.setTag(prop, val.substr(0, 200));
		},
		log: ex => {
			if (!ex) ex = '';

			if (not_local) Sentry.captureException(ex);
		}
	},
	cloneDeep = val => {
		return typeof val === 'undefined' ? null : JSON.parse(JSON.stringify(val));
	},
	isJsonString = str => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}

		return true;
	},
	onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	},
	cleanString = str => {
		str = (str || '')
			.replace(/[\u2018\u2019\u2032\u2035]/g, "'") // directional single quotes (or similar)
			.replace(/[\u201C\u201D\u2033\u2036]/g, '"') // directional double quotes (or similar)
			.replace(/[\u2013\u2014\u2015]/g, '-') // special dashes
			.replace(/[\u2026]/g, '...'); // ellipsis

		const dirty_str = `${str}`;

		str = str
			.replace(/[^\x00-\x7F]/g, '') // any remaining chars with ASCII code greater than 127
			.replace(/&([^\s]*);/g, '') // html-escaped characters
			.replace(/&#([^\s]*)/g, '') // more html-escaped characters
			.replace(/<|>/g, ''); // < or >

		return {
			clean: str,
			dirty: str === dirty_str ? '' : dirty_str
		};
	},
	adminUrl = use_cdn => {
		const { REACT_APP_ENV } = process.env,
			urls = {
				local: 'https://admin.devapp.wisconsin.edu/api/',
				staging: 'https://admin.devapp.wisconsin.edu/api/',
				practice: 'https://admin.practiceapp.wisconsin.edu/api/',
				production: use_cdn
					? 'https://d2dbtqz9gq3ks4.cloudfront.net/api/'
					: 'https://admin.apply.wisconsin.edu/api/'
			};

		return urls[REACT_APP_ENV];
	},
	scrollTo = (y = 0, x = 0) => {
		try {
			window.scrollTo({ top: y, left: x, behavior: 'smooth' });
		} catch (ex) {
			window.scrollTo(x, y);
		}
	},
	toggleArray = (arr, val) => {
		arr = [...arr];

		let i = arr.indexOf(val);

		if (i > -1) {
			arr.splice(i, 1);
		} else {
			arr.push(val);
		}

		return arr;
	},
	addrIsPOBox = addr => {
		// checks whether or not the input begins with some variation of "P.O. Box"

		if ((addr.country || 'USA') === 'USA') {
			if (
				!(addr.address_1 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_1';
			}

			if (
				!(addr.address_2 || '')
					.replace(/[^a-zA-Z\d]+/g, '')
					.toLowerCase()
					.indexOf('pobox')
			) {
				return 'address_2';
			}
		}

		return '';
	},
	findReferrer = (rec, referrer_to_find) => {
		let _rec = cloneDeep(rec);
		delete _rec._created;
		delete _rec._submitted;

		return _.isEqual(_rec, referrer_to_find);
	},
	campusLogos = {
		EAU: require('assets/img/campusLogos/UWEauClaire.png'),
		EAU_BRN: require('assets/img/campusLogos/EAU_BRN.png'),
		GBY: require('assets/img/campusLogos/UWGreenBay.png'),
		MAN: require('assets/img/campusLogos/MAN.png'),
		MNT: require('assets/img/campusLogos/MNT.png'),
		SHB: require('assets/img/campusLogos/SHB.png'),
		LAC: require('assets/img/campusLogos/UWLacrosse.png'),
		MSN: require('assets/img/campusLogos/UWMadison.png'),
		MIL: require('assets/img/campusLogos/UWMilwaukee.png'),
		WAK: require('assets/img/campusLogos/WAK.png'),
		WSH: require('assets/img/campusLogos/WSH.png'),
		OSH: require('assets/img/campusLogos/UWOshkosh.png'),
		FDL: require('assets/img/campusLogos/FDL.png'),
		FOX: require('assets/img/campusLogos/FOX.png'),
		PKS: require('assets/img/campusLogos/UWParkside.png'),
		PLT: require('assets/img/campusLogos/UWPlatteville.png'),
		BRB: require('assets/img/campusLogos/BRB.png'),
		RLN: require('assets/img/campusLogos/RLN.png'),
		RVF: require('assets/img/campusLogos/UWRiverfalls.png'),
		STP: require('assets/img/campusLogos/UWStevensPoint.png'),
		MSF: require('assets/img/campusLogos/MSF.png'),
		MTH: require('assets/img/campusLogos/MTH.png'),
		STO: require('assets/img/campusLogos/UWStout.png'),
		SUP: require('assets/img/campusLogos/UWSuperior.png'),
		WTW: require('assets/img/campusLogos/UWWhitewater.png'),
		RCK: require('assets/img/campusLogos/RCK.png'),

		XMI: require('assets/img/campusLogos/UWMilwaukee.png'),
		XPK: require('assets/img/campusLogos/UWParkside.png')
	},
	optsTerritory = {
		ASM: 'AS',
		GUM: 'GU',
		MNP: 'MP',
		PRI: 'PR',
		VIR: 'VI'
	},
	optsBadState = {
		MH: 'MHL',
		FM: 'FSM',
		PW: 'PLW'
	},
	optsVisa = [
		'U.S. Refugee/Granted Political Asylum',
		'U.S. Permanent Resident',
		'Applied/Applying for U.S. Permanent Resident Status'
	],
	MSG_DIRECT_ADMIT = {
		check_for_record: (strResp, _email) => {
			const msgs = {
				claimed: (
					<>
						<h1>Direct Admit already claimed</h1>
						<p>
							<strong>{_email}</strong> has already been claimed by a Universities of Wisconsin Admission
							account. If this is you, go ahead and log in (with your personal email).
						</p>
					</>
				),
				'not found': (
					<>
						<h1>Direct Admit not found</h1>
						<p>
							We can't find a Direct Admit offer made to <strong>{_email}</strong>. If you believe you
							should have one, please contact your high school for assistance.
						</p>
						<p>Would you like to create a traditional application account instead?</p>
					</>
				)
			};

			return (
				msgs[strResp] || (
					<>
						<h1>Oops!</h1>
						<p>
							Sorry, something went wrong on our end. Please try again and let us know if the problem
							continues.
						</p>
					</>
				)
			);
		},
		send_email: (strResp, _email) => {
			const msgs = {
				'Record already claimed': (
					<>
						<h1>Direct Admit already claimed</h1>
						<p>
							<strong>{_email}</strong> has already been claimed by a Universities of Wisconsin Admission
							account. If this is you, go ahead and log in (not with your high school email).
						</p>
					</>
				),
				'Record not found': (
					<>
						<h1>Direct Admit not found</h1>
						<p>
							Do you have a Direct Admit letter referencing <strong>{_email}</strong>? Is it for the
							current academic year?
						</p>
						<p>If so, please contact your high school for further assistance.</p>
					</>
				)
			};

			return (
				msgs[strResp] || (
					<>
						<h1>Oops!</h1>
						<p>
							Sorry, something went wrong on our end. Please try again and let us know if the problem
							continues.
						</p>
					</>
				)
			);
		},
		verify_record: (strResp, _email) => {
			const msgs = {
				'Code mismatch': (
					<>
						<h1>Code mismatch</h1>
						<p>Hmm, that's not the code we sent you.</p>
					</>
				),
				'Code expired': (
					<>
						<h1>Code expired</h1>
						<p>Looks like that code has expired.</p>
					</>
				),
				'Record already claimed': (
					<>
						<h1>Direct Admit already claimed</h1>
						<p>
							That's odd. It looks like <strong>{_email}</strong> has already been claimed by a
							Universities of Wisconsin Admission account. If this is you, go ahead and log in (not with
							your high school email).
						</p>
					</>
				)
				/*  not accounted for:
						Record not found
						Code not generated
				*/
			};

			return (
				msgs[strResp] || (
					<>
						<h1>Oops!</h1>
						<p>
							Sorry, something went wrong on our end. Please try again and let us know if the problem
							continues.
						</p>
					</>
				)
			);
		}
	};
