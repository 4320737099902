import React, { Component } from 'react';
import withRouter from 'components/Wrappers/withRouter';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Row, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'underscore';
import 'react-confirm-alert/src/react-confirm-alert.css';

import API from 'components/api';
import { Card } from 'components/Card/Card';
import Button from 'components/CustomButton';
import CheckboxList from 'components/controls/checkbox_list';
import DateInput from 'components/controls/date';
import PhoneControl from 'components/controls/phone';
import Select from 'components/controls/select';
import Textbox from 'components/controls/textbox';
import RadioList from 'components/controls/radio_list';
import ZipInput from 'components/controls/zip';
import { cloneDeep, optsVisa, scrollTo, REGEXES } from 'components/modules/_misc';
import { calculateReciprocityStatus } from 'components/modules/app';
import { months_passed, years_passed } from 'components/modules/date';
import { legal_genders, states } from 'components/modules/opts';
import { adult_age, colleges, RECIPROCITY } from 'components/modules/reciprocity';
import { GlobalActions } from 'reducers/global';
import { UserActions } from 'reducers/user';

class Reciprocity extends Component {
	constructor(props) {
		super(props);

		let { reciprocity } = props,
			key = Object.keys(reciprocity)[0], // TODO: sort and pull the latest one
			_reciprocity = cloneDeep(key ? reciprocity[key] : RECIPROCITY);

		_reciprocity = this.prefillData(_reciprocity);

		this.state = {
			current_key: key || '',
			reciprocity: _reciprocity,
			submitted: (_reciprocity.submit_date || '0000-00-00 00:00:00') !== '0000-00-00 00:00:00',
			age: -1,
			ssn_confirm: _reciprocity.ssn || '',
			show_saved: false,
			show_submitted: false,
			show_info_modal: false,
			show_campuses_modal: false
		};

		this.saved_recip = cloneDeep(_reciprocity);
	}

	componentDidMount = () => {
		const { reciprocity } = this.state;
		this.onChangeDOB(reciprocity.dob || '');

		this._mounted = true;
	};

	componentWillUnmount = () => {
		this._mounted = false;
	};

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	prefillData = recip => {
		const { apps, user_id } = this.props,
			prefills = {
				ssn: app => app.json_obj.personal_information.social_security_number,
				dob: app => app.json_obj.personal_information.date_of_birth,
				gender: app => app.json_obj.personal_information.gender,
				us_citizen: app => {
					const { citizen_country_code } = app.json_obj.personal_information;
					if (citizen_country_code) return citizen_country_code === 'USA' ? 'Yes' : 'No';
				},
				addr_street: app => {
					let { address_1, address_2 } = app.json_obj.contact_information.permanent_address,
						str = '';

					str += address_1;
					if (!str && address_2) str += `, ${address_2}`;

					return str;
				},
				phone: app => app.json_obj.contact_information.preferred_phone_number || app.phone_number,
				addr_city: app => app.json_obj.contact_information.permanent_address.city,
				addr_state: app => app.json_obj.contact_information.permanent_address.state,
				addr_zip: app => app.json_obj.contact_information.permanent_address.zip,
				provide_parent_info: app => app.json_obj.residency.provide_parent_info,
				relationship: app => app.json_obj.residency.relationship,
				relationship_us_citizen: app => app.json_obj.residency.relationship_us_citizen,
				relationship_visa_type: app => app.json_obj.residency.relationship_visa_type,
				relationship_visa_type_other: app => app.json_obj.residency.relationship_visa_type_other,
				relationship_lived_wi_12_months: app => app.json_obj.residency.relationship_lived_wi_12_months,
				relationship_employed: app => app.json_obj.residency.relationship_employed,
				relationship_income_taxes: app => app.json_obj.residency.relationship_income_taxes,
				relationship_registered_vote: app => app.json_obj.residency.relationship_registered_vote,
				relationship_valid_license: app => app.json_obj.residency.relationship_valid_license,
				self_live_in_wi: app => app.json_obj.residency.self_live_in_wi,
				self_lived_wi_date: app => app.json_obj.residency.self_lived_wi_date,
				self_lived_wi_12_months: app => app.json_obj.residency.self_lived_wi_12_months,
				self_employed: app => app.json_obj.residency.self_employed,
				self_income_taxes: app => app.json_obj.residency.self_income_taxes,
				self_registered_vote: app => app.json_obj.residency.self_registered_vote,
				self_valid_license: app => app.json_obj.residency.self_valid_license,
				self_claim_dependent: app => app.json_obj.residency.self_claim_dependent,
				self_wi_hs_grad: app => {
					const { highschools } = app.json_obj.academic_background,
						wi_hs = highschools?.some(
							hs => hs?.did_graduate === 'Yes' && hs?.selected_highschool?.field_state === 'WI'
						);

					if (wi_hs) return 'Yes';
					if (!!highschools?.length) return 'No';
				},
				self_he_enrolled: app => app.json_obj.residency.self_he_enrolled
			};

		if (!recip.campuses.length) {
			let _apps = Object.keys(apps)
				.map(_app_id => apps[_app_id])
				.sort((a, b) => {
					if (a.application_id === '6737a9a45ec51') return -2;
					if (a.date_submitted && !b.date_submitted) return -1;
					if (!a.date_submitted && b.date_submitted) return 1;

					return 0;
				});

			Object.keys(prefills).forEach(key => {
				const arr = _apps.map(prefills[key]).filter(val => !!val);
				if (arr.length) recip[key] = arr[0];
			});
		}

		recip.user_id = user_id;

		return recip;
	};

	canSave = () => {
		let { reciprocity } = this.state,
			{ campuses, term_name, term_year } = reciprocity;

		if (_.isEqual(reciprocity, this.saved_recip)) return false;

		return !!term_name && !isNaN(term_year) && term_year > 0 && !!campuses.length;
	};

	canSubmit = (reciprocity, onChange) => {
		const { age, ssn_confirm, submitted } = this.state,
			{
				campuses,
				term_year,
				term_name,
				dob,
				gender,
				ssn,
				us_citizen,
				provide_parent_info,
				addr_street,
				addr_city,
				addr_state,
				addr_zip,
				phone,
				relationship,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_wi_hs_grad,
				self_he_enrolled
			} = reciprocity;

		// tms: this is just for allowing/blocking the submit button, not determining status
		if (!onChange) {
			if (submitted) return campuses.length > this.saved_recip.campuses.length;

			if (!_.isEqual(reciprocity, this.saved_recip)) return false;
		}

		let requiredFields = [
				term_name,
				dob,
				gender,
				ssn,
				us_citizen,
				addr_street,
				addr_city,
				addr_state,
				addr_zip,
				phone,
				self_live_in_wi,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_wi_hs_grad,
				self_he_enrolled
			],
			blockConditions = [
				ssn !== ssn_confirm,
				age <= 0,
				self_live_in_wi === "Yes, but I haven't always" &&
					(!self_lived_wi_date || months_passed(self_lived_wi_date) < 0),
				age >= adult_age && !provide_parent_info,
				relationship_us_citizen === 'No' && !relationship_visa_type,
				!!relationship_visa_type === 'Other' && !relationship_visa_type_other
			];

		if (age < adult_age || provide_parent_info === 'Yes') {
			requiredFields.push(relationship);
			requiredFields.push(relationship_us_citizen);
			requiredFields.push(relationship_lived_wi_12_months);
			requiredFields.push(relationship_employed);
			requiredFields.push(relationship_income_taxes);

			if (
				relationship_lived_wi_12_months === 'No' ||
				relationship_employed !== 'Wisconsin' ||
				relationship_income_taxes !== 'Yes'
			) {
				requiredFields.push(relationship_registered_vote);
				requiredFields.push(relationship_valid_license);
			}
		}

		return (
			!isNaN(term_year) &&
			term_year > 0 &&
			!!campuses.length &&
			requiredFields.every(val => !!val.length) &&
			blockConditions.every(cond => !cond)
		);
	};

	onChange = (prop, val) => {
		let age = 0 + this.state.age,
			reciprocity = cloneDeep(this.state.reciprocity);

		if (prop === 'term') {
			reciprocity.term_name = val ? val.split(' ')[0] : '';
			reciprocity.term_year = val ? Number(val.split(' ')[1]) : -1;
		} else {
			reciprocity[prop] = val;
		}

		const { self_live_in_wi, relationship_us_citizen, relationship_visa_type, provide_parent_info } = reciprocity,
			parent_fields_shown = (age > -1 && age < adult_age) || provide_parent_info === 'Yes';

		if (prop === 'dob') age = this.onChangeDOB(val);

		if (self_live_in_wi !== "Yes, but I haven't always") reciprocity.self_lived_wi_date = '';

		if (relationship_us_citizen !== 'No') reciprocity.relationship_visa_type = '';

		if (relationship_visa_type !== 'Other') reciprocity.relationship_visa_type_other = '';

		if (age >= adult_age && provide_parent_info === 'No') {
			reciprocity.relationship = '';
			reciprocity.relationship_us_citizen = '';
			reciprocity.relationship_visa_type = '';
			reciprocity.relationship_visa_type_other = '';
			reciprocity.relationship_lived_wi_12_months = '';
			reciprocity.relationship_employed = '';
			reciprocity.relationship_income_taxes = '';
			reciprocity.relationship_registered_vote = '';
			reciprocity.relationship_valid_license = '';
		}

		reciprocity.status = this.canSubmit(reciprocity, true)
			? calculateReciprocityStatus(reciprocity, parent_fields_shown)
			: 'WIP';

		this.setState({ reciprocity: reciprocity });
	};

	onChangeDOB = str => {
		let _age = -1;

		if (str.match(REGEXES.dob)) {
			_age = years_passed(str);
			if (_age < 10 || _age > 110) _age = -1;
		}

		this.setState({ age: _age });

		return _age;
	};

	onSave = e => {
		const { updateReciprocity, captureError } = this.props,
			{ reciprocity, submitted } = this.state,
			stateKey = `show_${submitted ? 'submitted' : 'saved'}`;

		e.preventDefault();
		e.stopPropagation();

		this.setState({ [stateKey]: true });

		setTimeout(() => {
			this.setState({ [stateKey]: false });
		}, 3000);

		updateReciprocity(reciprocity);
		this.saved_recip = reciprocity;

		this.getAPI()
			.saveReciprocity(reciprocity)
			.catch(ex => captureError(ex));
	};

	onSubmit = e => {
		const { updateReciprocity } = this.props,
			{ reciprocity, current_key, submitted } = this.state,
			confirmWindow = onClose => {
				return submitted ? (
					<>
						<h1>Add Campuses</h1>
						<p>
							This will add the following campuses to your existing {current_key} reciprocity application:
						</p>
						<ul>
							{reciprocity.campuses
								.filter(c => !this.saved_recip.campuses.includes(c))
								.map(str => (
									<li key={str}>{str}</li>
								))}
						</ul>
						<p>Are you sure you want to submit?</p>
						<div className='react-confirm-alert-button-group'>
							<button
								onClick={() => {
									this.onSave(e);
									onClose();

									setTimeout(() => {
										scrollTo();
									}, 50);
								}}>
								Yes, Submit
							</button>
							<button onClick={onClose}>Cancel</button>
						</div>
					</>
				) : (
					<>
						<h1>Submit Reciprocity Application</h1>
						<p>
							Please confirm that your reciprocity application is accurate. Once submitted, you will be
							able to add additional Minnesota campuses, but you will not be able to make any other
							changes.
						</p>
						<p>Are you sure you want to submit?</p>
						<div className='react-confirm-alert-button-group'>
							<button
								onClick={() => {
									this.getAPI()
										.submitReciprocity(reciprocity)
										.then(resp => {
											const _reciprocity = cloneDeep(reciprocity);
											_reciprocity.submit_date = resp.row.submit_date;

											updateReciprocity(_reciprocity);
											this.saved_recip = reciprocity;

											this.setState({
												reciprocity: _reciprocity,
												submitted: true,
												show_submitted: true
											});

											setTimeout(() => {
												this.setState({ show_submitted: false });
											}, 3000);

											setTimeout(() => {
												scrollTo(0);
											}, 50);
										})
										.catch(ex => captureError(ex));

									onClose();
								}}>
								Yes, Submit
							</button>
							<button onClick={onClose}>Cancel</button>
						</div>
					</>
				);
			};

		e.preventDefault();
		e.stopPropagation();

		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='react-confirm-alert'>
						<div className='react-confirm-alert-body'>{confirmWindow(onClose)}</div>
					</div>
				);
			}
		});
	};

	renderInfoModal = () => {
		const { show_info_modal } = this.state;

		return (
			<Modal show={show_info_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>
						MN-WI Tuition Reciprocity Application Instructions
						<a onClick={() => this.setState({ show_info_modal: false })} className='close-link-top'>
							Close
						</a>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='recip-faq'>
					<h3>Deadline</h3>
					<p>
						The application deadline is the last day of classes at the institution you are or will be
						attending for the term benefits are needed. Applications <strong>will not</strong> be processed
						retroactively. If you wish to participate in the program for the entire academic year, your
						application must be correctly completed and submitted by the last day of scheduled classes in
						the fall term at the institution you are or will be attending.
					</p>

					<h3>Who is Eligible</h3>
					<p>
						Wisconsin residents attending Minnesota public institutions and Minnesota residents attending
						Wisconsin public institutions are eligible. Professional students enrolling in a Doctor of
						Medicine, Doctor of Dental Sciences, or Doctor of Veterinary Medicine program in the public
						institutions of either state are ineligible for reciprocity tuition.
					</p>

					<h3>Notification of Acceptance</h3>
					<p>
						You will receive the results of your application within four to six weeks after you have
						applied. If you do not receive results within six weeks, you should assume your application was
						not properly submitted and apply again.
					</p>

					<h3>Application for Admission</h3>
					<p>
						Application to the Minnesota-Wisconsin Reciprocity Program does not constitute application for
						admission to an educational institution. Regardless of your eligibility for tuition reciprocity,
						you must still apply and qualify for admission to the school of your choice, following the
						procedures required by that institution.
					</p>

					<h3>Administrative Agencies</h3>
					<p>
						The WHEAB will determine the residency and eligibility status of Wisconsin applicants enrolled
						in Minnesota public institutions and will certify to the Minnesota public institutions which
						students are eligible to pay the established reciprocity fee. Similarly, the MOHE will determine
						the residency and eligibility status of Minnesota applicants in Wisconsin public institutions
						and will certify to the Wisconsin public institutions which students are eligible to pay the
						established reciprocity fee.
					</p>

					<h3>Eligible Institutions</h3>
					<section className='recip-eligible-uw'>
						<h4>Wisconsin</h4>

						<h5>Universities of Wisconsin</h5>
						<ul>
							{colleges.uw.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>

						<h5>Universities of Wisconsin Colleges</h5>
						<ul>
							{colleges.uw_colleges.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>
					</section>
					<section className='recip-eligible-mn'>
						<h4>Minnesota</h4>

						<h5>University of Minnesota</h5>
						<ul>
							{colleges.mn.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>

						<h5>Minnesota State Universities</h5>
						<ul>
							{colleges.mn_state.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>

						<h5>Minnesota State Colleges</h5>
						<ul>
							{colleges.mn_state_college.map((name, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ show_info_modal: false })}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderCampusesModal = () => {
		const { show_campuses_modal, reciprocity, submitted } = this.state,
			{ campuses } = reciprocity,
			opts = {
				mn: colleges.mn,
				mn_state: colleges.mn_state,
				mn_state_college: colleges.mn_state_college
			},
			values = {
				mn: colleges.mn.filter(c => campuses.includes(c)),
				mn_state: colleges.mn_state.filter(c => campuses.includes(c)),
				mn_state_college: colleges.mn_state_college.filter(c => campuses.includes(c))
			};

		if (submitted) {
			Object.keys(opts).forEach(key => {
				opts[key] = opts[key].map(str => {
					return {
						val: str,
						label: str,
						disabled: this.saved_recip.campuses.includes(str)
					};
				});
			});
		}

		return (
			<Modal show={show_campuses_modal}>
				<Modal.Header>
					<Modal.Title className='h2'>Select the campus(es) you plan to attend</Modal.Title>
				</Modal.Header>
				<Modal.Body className='recip-faq'>
					<div className='campus-select-column'>
						<CheckboxList
							name='campuses'
							values={values.mn}
							opts={opts.mn}
							onChange={val => {
								const arr = val.concat(values.mn_state).concat(values.mn_state_college);
								this.onChange('campuses', arr);
							}}
							label='University of Minnesota'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>

						<CheckboxList
							name='campuses'
							values={values.mn_state}
							opts={opts.mn_state}
							onChange={val => {
								const arr = values.mn.concat(val).concat(values.mn_state_college);
								this.onChange('campuses', arr);
							}}
							label='Minnesota State Universities'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>
					</div>
					<div className='campus-select-column'>
						<CheckboxList
							name='campuses'
							values={values.mn_state_college}
							opts={opts.mn_state_college}
							onChange={val => {
								const arr = values.mn.concat(values.mn_state).concat(val);
								this.onChange('campuses', arr);
							}}
							label='Minnesota State Colleges'
							hide_optional={true}
							cols={12}
							forceNewLines={true}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						bsStyle='info'
						className='save-btn'
						fill
						onClick={() => this.setState({ show_campuses_modal: false })}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	renderParentForm = () => {
		let { reciprocity, age, submitted } = this.state,
			{
				provide_parent_info,
				relationship,
				relationship_us_citizen,
				relationship_visa_type,
				relationship_visa_type_other,
				relationship_lived_wi_12_months,
				relationship_employed,
				relationship_income_taxes,
				relationship_registered_vote,
				relationship_valid_license
			} = reciprocity,
			strRelation = relationship.toLowerCase();

		// TODO:  what's the message to adults about qualifying via parent?

		return (
			<>
				<h2>Parent Information</h2>

				{age >= adult_age && (
					<>
						<p>
							Typically, the easiest route to qualify for the Wisconsin resident tuition rate is through
							providing information about your parents. Do you want to provide parent's information for
							residency purposes?
						</p>
						<Row>
							<RadioList
								disabled={submitted}
								name='provide_parent_info'
								value={provide_parent_info}
								label="Do you want to provide parent's information for residency purposes?"
								opts={['Yes', 'No']}
								onChange={val => this.onChange('provide_parent_info', val)}
								required={true}
								cols={12}
							/>
						</Row>
					</>
				)}

				{((age > -1 && age < adult_age) || provide_parent_info === 'Yes') && (
					<>
						<p>
							Please select the parent/guardian who best qualifies you for the Wisconsin resident tuition
							rate. If neither parent resides in Wisconsin, please list the parent whose information you
							are most familiar with.
						</p>

						<Row>
							<Select
								disabled={submitted}
								name='relationship'
								value={relationship}
								label='Chosen relationship'
								placeholder='Select Relationship'
								opts={['Mother', 'Father', 'Stepmother', 'Stepfather', 'Legal Guardian/Caretaker']}
								required={true}
								cols={6}
								onChange={val => this.onChange('relationship', val)}
								tooltip={
									<a id='relationshipTooltip'>
										<img
											className='informationTooltip'
											src={require('assets/img/Information.png')}
											alt='Information'
										/>
									</a>
								}
							/>
						</Row>

						<Tooltip
							anchorId='relationshipTooltip'
							className='tooltipContainer'
							delayHide={1000}
							effect='solid'
							content={
								<>
									<ul>
										<li>
											<strong>Parent (Mother or Father)</strong>: The student's biological or
											adoptive parent.
										</li>
										<li>
											<strong>Stepparent (Stepmother or Stepfather)</strong>: The legal spouse of
											the student's biological or adoptive parent. A stepparent must be currently
											married to the student's parent.
										</li>
										<li>
											<strong>Legal Guardian/Caretaker</strong>: A legal guardian is a person
											designated by the court to assume care of and make decisions for the
											student. A caretaker is a person designated by an individual (such as the
											student's parent) or an entity other than the court to assume care of and
											make decisions for the student.
										</li>
										<li>
											<strong>Other</strong>: Students may use the “other” category when
											completing the parent information section if they do not have a parent,
											legal guardian, or caretaker whose information they can provide. The “other”
											category may include individuals who do not fit one of the other categories
											listed above.
										</li>
									</ul>
								</>
							}
						/>

						{!!relationship && (
							<>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_us_citizen'
										value={relationship_us_citizen}
										label={`Is your ${strRelation} a U.S. citizen?`}
										opts={['Yes', 'No', 'Prefer not to respond at this time']}
										onChange={val => this.onChange('relationship_us_citizen', val)}
										required={true}
										cols={12}
									/>
								</Row>

								{relationship_us_citizen === 'No' && (
									<>
										<Row>
											<Select
												disabled={submitted}
												name='relationship_visa_type'
												value={relationship_visa_type}
												label={`${relationship}'s visa type`}
												placeholder='Select Visa Type'
												opts={optsVisa}
												onChange={val => this.onChange('relationship_visa_type', val)}
												required={true}
												cols={6}
											/>

											{relationship_visa_type === 'Other' && (
												<Textbox
													disabled={submitted}
													name='relationship_visa_type_other'
													value={relationship_visa_type_other}
													label="Please explain 'Other' status"
													placeholder="Please explain 'Other' status"
													onChange={val => this.onChange('relationship_visa_type_other', val)}
													required={true}
													cols={6}
													maxLength={100}
												/>
											)}
										</Row>
									</>
								)}

								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_lived_wi_12_months'
										value={relationship_lived_wi_12_months}
										label={`Has your ${strRelation} physically resided full-time in Wisconsin for the past 12 months?`}
										opts={['Yes', 'No']}
										onChange={val => this.onChange('relationship_lived_wi_12_months', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_employed'
										value={relationship_employed}
										label={`Where is your ${strRelation} employed?`}
										opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
										onChange={val => this.onChange('relationship_employed', val)}
										required={true}
										cols={12}
									/>
								</Row>
								<Row>
									<RadioList
										disabled={submitted}
										name='relationship_income_taxes'
										value={relationship_income_taxes}
										label={`Has your ${strRelation} filed a Wisconsin resident income tax return for the most recent tax year?`}
										opts={['Yes', 'No, did not file', 'No, filed in a different state']}
										onChange={val => this.onChange('relationship_income_taxes', val)}
										required={true}
										cols={12}
									/>
								</Row>

								{(relationship_lived_wi_12_months === 'No' ||
									relationship_employed !== 'Wisconsin' ||
									relationship_income_taxes !== 'Yes') && (
									<>
										<Row>
											<RadioList
												disabled={submitted}
												name='relationship_registered_vote'
												value={relationship_registered_vote}
												label={`Was Wisconsin the last place your ${strRelation} registered to vote or voted?`}
												opts={['Yes', 'No']}
												onChange={val => this.onChange('relationship_registered_vote', val)}
												required={true}
												cols={12}
											/>
										</Row>
										<Row>
											<RadioList
												disabled={submitted}
												name='relationship_valid_license'
												value={relationship_valid_license}
												label={`Does your ${strRelation} hold a valid Wisconsin driver’s license?`}
												opts={['Yes', 'No']}
												onChange={val => this.onChange('relationship_valid_license', val)}
												required={true}
												cols={12}
											/>
										</Row>
									</>
								)}
							</>
						)}
					</>
				)}
			</>
		);
	};

	renderForm = () => {
		let { reciprocity, age, ssn_confirm, submitted } = this.state,
			{
				campuses,
				gender,
				dob,
				ssn,
				us_citizen,
				addr_street,
				addr_city,
				addr_state,
				addr_zip,
				phone,
				self_live_in_wi,
				self_lived_wi_date,
				self_employed,
				self_income_taxes,
				self_registered_vote,
				self_valid_license,
				self_claim_dependent,
				self_lived_wi_12_months,
				self_wi_hs_grad,
				self_he_enrolled
			} = reciprocity,
			current_term = `${reciprocity.term_name} ${reciprocity.term_year}`,
			invalid_wi_lived_date = !!self_lived_wi_date && months_passed(self_lived_wi_date) < 0;

		return (
			<>
				<hr />

				<Row>
					<Select
						name='term'
						value={current_term}
						label='Term'
						placeholder='Select Term'
						opts={['Spring 2025', 'Summer 2025', 'Fall 2025']} // TODO: set real list of options
						onChange={val => this.onChange('term', val)}
						required={true}
						cols={6}
						disabled={submitted}
					/>
				</Row>
				<Row>
					<div className='col-md-6'>
						<p>What school(s) do you plan to attend?</p>
						<p>
							<a onClick={() => this.setState({ show_campuses_modal: true })}>Select campuses</a>
						</p>
					</div>

					{!!campuses.length && (
						<div className='col-md-6'>
							<p className='mn-campus-list'>
								{campuses.map(str => (
									<span key={str}>{str}</span>
								))}
							</p>
						</div>
					)}
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='gender'
						value={gender}
						label='Legal Sex'
						placeholder='Select Legal Sex'
						opts={legal_genders}
						onChange={val => this.onChange('gender', val)}
						required={true}
						cols={6}
					/>
					<DateInput
						disabled={submitted}
						value={dob}
						label='Date of Birth'
						fullDate={true}
						onChange={(val, isValid) => this.onChange('dob', val)}
						required={true}
						cols={6}
						isInvalid={age < 0}
					/>
				</Row>
				<Row>
					<Textbox
						disabled={submitted}
						type='ssn'
						name='ssn'
						value={ssn}
						label='US Social Security Number'
						onChange={val => this.onChange('ssn', val)}
						required={true}
						cols={6}
						isInvalid={ssn.length !== 11}
					/>

					<Textbox
						disabled={submitted}
						type='ssn'
						name='ssn_confirm'
						value={ssn_confirm}
						label='Confirm Social Security Number'
						onChange={val => this.setState({ ssn_confirm: val })}
						required={true}
						cols={6}
						isInvalid={ssn !== ssn_confirm}
					/>
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='us_citizen'
						value={us_citizen}
						label='Are you a US Citizen?'
						placeholder='(Select)'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('us_citizen', val)}
						required={true}
						cols={6}
					/>
					<Select
						disabled={submitted}
						name='self_live_in_wi'
						value={self_live_in_wi}
						label='Do you live in Wisconsin?'
						placeholder='Select Option'
						opts={['No', "Yes, but I haven't always", 'Yes, my entire life']}
						required={true}
						cols={6}
						onChange={val => this.onChange('self_live_in_wi', val)}
					/>
				</Row>

				{self_live_in_wi === "Yes, but I haven't always" && (
					<Row>
						<DateInput
							disabled={submitted}
							value={self_lived_wi_date}
							label='When did you start living in Wisconsin?'
							onChange={(val, isValid) => this.onChange('self_lived_wi_date', val)}
							fullDate={false}
							required={true}
							cols={6}
							isInvalid={invalid_wi_lived_date}
						/>
					</Row>
				)}

				<Row>
					<Textbox
						disabled={submitted}
						type='addr_street'
						name='addr_street'
						value={addr_street}
						label='Address: Street'
						placeholder='Address - Street'
						onChange={val => this.onChange('addr_street', val)}
						required={true}
						cols={6}
					/>
					<Textbox
						disabled={submitted}
						type='adr_city'
						name='adr_city'
						value={addr_city}
						label='Address: City'
						placeholder='Address - City'
						onChange={val => this.onChange('adr_city', val)}
						required={true}
						cols={6}
					/>
				</Row>
				<Row>
					<Select
						disabled={submitted}
						name='addr_state'
						value={addr_state}
						label='Address: State'
						placeholder='Select State'
						opts={states}
						required={true}
						cols={6}
						onChange={val => this.onChange('addr_state', val)}
					/>
					<ZipInput
						disabled={submitted}
						name='addr_zip'
						value={addr_zip}
						label='Address: Zip / Postal Code'
						placeholder='Address - Zip / Postal Code'
						country='USA'
						required={true}
						cols={6}
						onChange={val => this.onChange('addr_zip', val)}
					/>
				</Row>
				<Row>
					<PhoneControl
						disabled={submitted}
						name='phone'
						value={phone}
						label='Phone'
						required={true}
						cols={6}
						onChange={val => this.onChange('phone', val)}
					/>
				</Row>

				<hr />

				<Row>
					<RadioList
						disabled={submitted}
						name='self_employed'
						value={self_employed}
						label='Where are you employed?'
						opts={['Wisconsin', 'Outside of Wisconsin', 'Not currently working']}
						onChange={val => this.onChange('self_employed', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_income_taxes'
						value={self_income_taxes}
						label='Have you filed a Wisconsin resident income tax return for the most recent tax year?'
						opts={['Yes', 'No, did not file', 'No, filed in a different state']}
						onChange={val => this.onChange('self_income_taxes', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_registered_vote'
						value={self_registered_vote}
						label='Was Wisconsin the last place you registered to vote or voted?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_registered_vote', val)}
						required={true}
						cols={12}
						tooltip={
							<a id='voteTooltip'>
								<img
									className='informationTooltip'
									src={require('assets/img/Information.png')}
									alt='Information'
								/>
							</a>
						}
					/>

					<Tooltip
						anchorId='voteTooltip'
						className='tooltipContainer'
						delayHide={1000}
						effect='solid'
						content={<p>If you are under the age of 18, please select no.</p>}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_valid_license'
						value={self_valid_license}
						label='Do you hold a valid Wisconsin driver’s license?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_valid_license', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_claim_dependent'
						value={self_claim_dependent}
						label='Did your parent(s)/guardian(s) claim you as a dependent for the most recent tax year?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_claim_dependent', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_lived_wi_12_months'
						value={self_lived_wi_12_months}
						label='Have you physically resided full-time in Wisconsin for the past 12 months?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_lived_wi_12_months', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_wi_hs_grad'
						value={self_wi_hs_grad}
						label='Have you graduated from a Wisconsin high school?'
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_wi_hs_grad', val)}
						required={true}
						cols={12}
					/>
				</Row>
				<Row>
					<RadioList
						disabled={submitted}
						name='self_he_enrolled'
						value={self_he_enrolled}
						label="Have you been enrolled at any institutions of higher education during the twelve months prior to today's date?"
						opts={['Yes', 'No']}
						onChange={val => this.onChange('self_he_enrolled', val)}
						required={true}
						cols={12}
					/>
				</Row>

				{age > -1 && this.renderParentForm(age)}
			</>
		);
	};

	render = () => {
		const { show_saved, show_submitted, submitted, reciprocity } = this.state;

		return (
			<Card
				title='Minnesota Reciprocity'
				content={
					<form id='form'>
						{this.renderInfoModal()}
						{this.renderCampusesModal()}

						{submitted && (
							<>
								<p className='mn-recip-submitted'>
									This application has been submitted, but you may add additional campuses and
									re-submit.
								</p>
								<hr />
							</>
						)}

						<p>
							Complete this application in full and save. Students enrolled under reciprocity who earned
							credits that were reported by the institution to the WHEAB during any semester of the
							current academic year will automatically have benefits renewed for the upcoming academic
							year at the institution they are currently attending. Therefore, these students need not
							reapply. If you, or the institution where you are enrolled, do not receive notification of
							your renewal status within three weeks of your application, please contact the Wisconsin
							Higher Educational Aids Board.
						</p>
						<p>
							<a onClick={() => this.setState({ show_info_modal: true })}>More information</a>
						</p>

						{this.renderForm()}
					</form>
				}
				buttons={
					<>
						{!submitted && (
							<Button
								form='form'
								className='back-btn'
								fill
								type='submit'
								disabled={!this.canSave() || show_saved}
								onClick={e => this.onSave(e)}>
								{show_saved ? 'Saved!' : 'Save'}
							</Button>
						)}

						<Button
							form='form'
							className='save-btn'
							fill
							type='submit'
							disabled={!this.canSubmit(reciprocity) || show_submitted}
							onClick={e => this.onSubmit(e)}>
							{show_submitted ? 'Submitted!' : 'Submit'}
						</Button>
					</>
				}
			/>
		);
	};
}

const mapStateToProps = state => ({
		reciprocity: state.user.reciprocity,
		apps: state.user.apps,
		user_id: state.user.user_id
	}),
	mapDispatchToProps = dispatch => ({
		login: obj => dispatch(UserActions.login(obj)),
		setUserProperty: (str_prop, val) => dispatch(UserActions.setUserProperty(str_prop, val)),
		captureError: err => dispatch(GlobalActions.captureError(err)),
		updateReciprocity: obj => dispatch(UserActions.updateReciprocity(obj))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reciprocity));
